import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';

import TagManager from 'react-gtm-module';

import App from 'js/App';

const tagManagerArgs = {
  gtmId: 'GTM-P6WJZ7C',
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <React.Fragment>
    <HashRouter>
      <App />
    </HashRouter>
  </React.Fragment>,
  document.getElementById('root')
);
