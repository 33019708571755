import React, { useState } from "react";
import Map from "js/pages/map";
import { DataContext } from "js/context/index";

import "sass/index.scss";

const App = () => {
  const [data, setData] = useState({
    activeSection: 0,
    showVideo: false,
    audioMuted: true,
    started: [0],
    finished: [0],
    replay: false,
    showVr: false,
    isMobile: window.innerWidth <= 768,
    multiplier:
      window.innerWidth <= 768
        ? window.innerWidth * 0.8
        : window.innerWidth * 0.9,
    base: window.innerWidth <= 768 ? 1080 : 1920,
  });
  const dataContext = { data, setData };

  let vh = window.innerHeight * 0.01;

  React.useEffect(() => {
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }, [vh]);

  return (
    <>
      <DataContext.Provider value={dataContext}>
        <Map />
      </DataContext.Provider>
    </>
  );
};

export default App;
