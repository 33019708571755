import React from 'react';
import { usePreview } from 'react-dnd-preview';
import 'js/components/Console/Preview/styles.scss';

export const Preview = () => {
  const { display, item, style } = usePreview();
  if (!display) {
    return null;
  }

  return (
    <div
      className='preview'
      style={{
        ...style,
        width: (window.innerWidth * 200) / 1920,
        maxWidth: 200,
      }}>
      <img src={item.icon} alt={item.name} />
    </div>
  );
};
