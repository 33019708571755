import React, { useState } from 'react';
import 'aframe';
import 'aframe-particle-system-component';

import 'js/components/VRoom/styles.scss';

const VRoom = () => {
  const [showInstruction, setShowInstruction] = useState(true);

  const isMobile = window.innerWidth <= 768;

  return (
    <div
      onMouseDown={() => setShowInstruction(false)}
      onTouchStart={() => setShowInstruction(false)}>
      {showInstruction && !isMobile && (
        <div className='drag-wrapper'>
          <img src='images/360-drag.png' alt='arrows and drag text' />
        </div>
      )}
      {isMobile ? (
        <video
          className='video-360'
          autoPlay
          muted
          loop
          src='videos/360video.mp4'
        />
      ) : (
        <a-scene device-orientation-permission-ui='enabled: false'>
          <a-camera look-controls='reverseMouseDrag: true' />
          <a-videosphere
            loop
            muted
            crossOrigin='anonymous'
            webkit-playsinline='true'
            playsinline='true'
            src='videos/360.mp4'
          />
        </a-scene>
      )}
    </div>
  );
};

export default VRoom;
