import React, { useContext, useMemo, useRef, useState } from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { useResizeDetector } from 'react-resize-detector';

import Path from 'js/components/Path';
import ContactUs from 'js/components/ContactUs';
import MapBtn from 'js/components/MapBtn';
import Presentation from 'js/components/Presentation';

import { useClickOutside } from 'js/hooks/useClickOutside';

import { DataContext } from 'js/context/index';

import { sections } from 'data/index';

import 'js/pages/map/styles.scss';

const Map = () => {
  const { ref, width, height } = useResizeDetector();

  const clickRef = useRef(null);

  const [showModal, setShowModal] = useState(false);

  useClickOutside(clickRef, () => showModal && setShowModal(false));

  const { data, setData } = useContext(DataContext);

  const btnId = useMemo(() => data.activeSection.btnId, [data]);

  const position = useMemo(() => {
    if (window.innerWidth <= 768) {
      return {
        scale: 0.25,
        minScale: 0.25,
        maxScale: 1,
        x: 0,
        y: 0,
        centerOnInit: true,
      };
    } else if (window.innerWidth <= 1200) {
      return {
        scale: 0.8,
        minScale: 0.8,
        maxScale: 2,
        centerOnInit: true,
        x: 0,
        y: 0,
      };
    } else if (window.innerWidth > 1200) {
      return {
        scale: 1,
        minScale: 0.8,
        maxScale: 1.5,
        centerOnInit: true,
        x: 0,
        y: 0,
      };
    }
  }, []);

  return (
    <>
      <div className='map'>
        <TransformWrapper
          minScale={position.minScale}
          maxScale={position.maxScale}
          initialScale={position.scale}
          initialPositionX={position.x}
          initialPositionY={position.y}
          centerOnInit={position.centerOnInit}>
          <TransformComponent>
            <video
              src='videos/map.mp4'
              autoPlay
              muted
              loop
              className='map__video'
              ref={ref}
            />

            {btnId !== undefined && <Path id={btnId} />}

            {width &&
              height &&
              sections
                .filter((section) => !!section.button)
                .map((section) => (
                  <MapBtn
                    key={section.id}
                    data={section}
                    activeSection={data.activeSection}
                    mapWidth={width}
                    mapHeight={height}
                  />
                ))}
          </TransformComponent>
        </TransformWrapper>
        <button
          onClick={() => setData({ ...data, audioMuted: !data.audioMuted })}
          className='map__audio-btn'>
          <img
            src={data.audioMuted ? 'images/soundOff.png' : 'images/soundOn.png'}
            alt='speaker'
          />
        </button>
        <img
          onClick={() => setShowModal(true)}
          className='map__eu-flags'
          src='images/eu.png'
          alt='au'
        />
        <div
          className={`map__modal  map__modal${
            showModal ? '--show' : '--hide'
          }`}>
          <div ref={clickRef} className='content'>
            <button onClick={() => setShowModal(false)}>x</button>

            <p>
              The project &quot;Research and development works on the
              development of a gamified training service&quot; is carried out
              under the Regional Operational Program of the Pomeranian
              Voivodeship for 2014-2020, Priority Axis 01. Expansion through
              innovation, Sub-measure 01.01.01. Expansion through innovation -
              subsidy support, co-financed from the European Regional
              Development Fund Co-financing agreement No.
              RPPM.01.01.01-22-0048/18. The aim of the project is to create an
              infrastructure for running an interactive gamified training
              service. Co-financing of the project from the EU: PLN 550,907.38
            </p>
            <img src='images/eu.png' alt='au' />
          </div>
        </div>
        <ContactUs />
      </div>
      <Presentation />
    </>
  );
};

export default Map;
