import React, { memo, useEffect } from 'react';
import { useDrag } from 'react-dnd';

import 'js/components/Console/Box/styles.scss';

export const Box = memo(function Box({
  name,
  desc,
  type,
  icon,
  audio,
  isDropped,
  draggedItem,
}) {
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type,
      item: { name, type, audio, icon },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [name, type, audio]
  );

  useEffect(() => {
    draggedItem(isDragging ? type : null);
  }, [type, isDragging]);

  return (
    <div className='console-box'>
      {isDropped && (
        <div className='console-box__description'>
          <h2>{name}</h2>
          <p>{desc}</p>
        </div>
      )}
      <div
        ref={drag}
        className='console-box__draggable'
        style={{
          width: (window.innerWidth * 200) / 1920,
          height: (window.innerWidth * 200) / 1920,
          maxWidth: 200,
          maxHeight: 200,
          opacity: isDragging ? 0 : 1,
          display: isDropped ? 'none' : 'block',
        }}>
        <img src={icon} alt={name} />
      </div>
      {!isDropped && <h2>{name}</h2>}
    </div>
  );
});
