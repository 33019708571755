export const sections = [
  {
    id: 1,
    btnId: 1,
    name: "Problem",
    question: "What problem do you solve?",
    video: "videos/map/1.mp4",
    connections: [2],
    button: {
      width: 90,
      height: 36,
      x: 287,
      y: 633,
    },
  },
  {
    id: 2,
    btnId: 2,
    name: "Solution",
    question: "What is your solution?",
    video: "videos/map/2.mp4",
    connections: [4, 3, 6],
    button: {
      width: 90,
      height: 36,
      x: 337,
      y: 452,
    },
  },
  {
    id: 3,
    btnId: 3,
    name: "Product",
    question: "What is the Training Console?",
    video: "videos/map/3.mp4",
    connections: [1, 4, 5, 6, 7],
    nextSections: [12, 13, 14, 15],
    button: {
      width: 90,
      height: 36,
      x: 719,
      y: 473,
    },
  },
  {
    id: 4,
    btnId: 4,
    name: "Traction",
    question: "Do you have any traction?",
    video: "videos/map/4.mp4",
    connections: [5, 7],
    button: {
      width: 90,
      height: 36,
      x: 801,
      y: 626,
    },
  },
  {
    id: 5,
    btnId: 5,
    name: "Market",
    question: "What is the market for it?",
    video: "videos/map/5.mp4",
    connections: [4, 6, 7],
    button: {
      width: 90,
      height: 36,
      x: 1065,
      y: 382,
    },
  },
  {
    id: 6,
    btnId: 6,
    name: "Competition",
    question: "What is your competition?",
    video: "videos/map/6.mp4",
    connections: [4, 5, 7],
    button: {
      width: 116,
      height: 36,
      x: 397,
      y: 294,
    },
  },
  {
    id: 7,
    btnId: 7,
    name: "Business Model",
    question: "What is your business model?",
    video: "videos/map/7.mp4",
    connections: [4, 5, 8],
    button: {
      width: 90,
      height: 56,
      x: 1112,
      y: 595,
    },
  },
  {
    id: 8,
    btnId: 8,
    name: "Financials",
    question: "How much money can this make?",
    video: "videos/map/8.mp4",
    connections: [9],
    button: {
      width: 116,
      height: 36,
      x: 1353,
      y: 434,
    },
  },
  {
    id: 9,
    btnId: 9,
    name: "Investment",
    question: "How much money do you need?",
    video: "videos/map/9.mp4",
    connections: [10],
    button: {
      width: 116,
      height: 36,
      x: 1521,
      y: 540,
    },
  },
  {
    id: 10,
    btnId: 10,
    name: "Team",
    question: "I want to meet your team",
    video: "videos/map/10.mp4",
    connections: [1, 3],
    button: {
      width: 90,
      height: 36,
      x: 1000,
      y: 872,
    },
  },
  {
    id: 12,
    btnId: 3,
    name: "Tech button",
    question: "Tech",
    video: "",
    nextSections: [12, 13, 14, 15],
  },
  {
    id: 13,
    btnId: 3,
    name: "UX button",
    question: "UX",
    video: "videos/map/3b.mp4",
    nextSections: [12, 13, 14, 15],
  },
  {
    id: 14,
    btnId: 3,
    name: "USP button",
    question: "USP",
    video: "videos/map/3c.mp4",
    nextSections: [12, 13, 14, 15],
  },
  {
    id: 15,
    btnId: 3,
    name: "Stage button",
    question: "Stage",
    video: "videos/map/3d.mp4",
    nextSections: [12, 13, 14, 15],
  },
];
