import {
  useState,
  useCallback,
  memo,
  useRef,
  useEffect,
  useContext,
} from 'react';

import { Dustbin } from 'js/components/Console/Dustbin';
import { Box } from 'js/components/Console/Box';
import { BaseImages } from 'js/components/Console//BaseImages';
import { Preview } from 'js/components/Console/Preview';

import update from 'immutability-helper';
import { TouchBackend } from 'react-dnd-touch-backend';
import { DndProvider } from 'react-dnd';

import { DataContext } from 'js/context';

import 'js/components/Console/styles.scss';

const hasNative =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

export const Console = memo(function Console({ onComplete }) {
  const { data } = useContext(DataContext);
  const { isMobile, multiplier, base } = data;

  const [dustbins] = useState([
    {
      accepts: 'projectors',
      icon: 'images/console/dustbins/projectors.png',
      width: 487.12,
      height: 265.95,
      x: 265.07,
      y: 183.95,
    },
    {
      accepts: 'laserSensors',
      icon: 'images/console/dustbins/laserSensors.png',
      width: 508.36,
      height: 288.13,
      x: 255.9,
      y: 241.94,
    },
    {
      accepts: 'smartWatches',
      icon: 'images/console/dustbins/smartWatches.png',
      width: 357.14,
      height: 408.54,
      x: 335.2,
      y: 287.25,
    },
    {
      accepts: 'depthCameras',
      icon: 'images/console/dustbins/depthCameras.png',
      width: 55.15,
      height: 599.98,
      x: 480,
      y: 70,
    },
  ]);

  const [boxes] = useState([
    {
      name: 'Projectors',
      type: 'projectors',
      icon: 'images/console/boxes/projectors.svg',
      desc: 'for recreating environments ',
      audio: 'audio/console/projectors.wav',
    },
    {
      name: 'Laser Sensors',
      type: 'laserSensors',
      icon: 'images/console/boxes/laserSensors.svg',
      desc: 'for the walls to be touch reactive',
      audio: 'audio/console/laserSensors.wav',
    },
    {
      name: 'Smart Watches',
      type: 'smartWatches',
      icon: 'images/console/boxes/smartWatches.svg',
      desc: 'for communication with players',
      audio: 'audio/console/smartWatches.wav',
    },
    {
      name: 'Depth Cameras',
      type: 'depthCameras',
      icon: 'images/console/boxes/depthCameras.svg',
      desc: 'to track players and objects',
      audio: 'audio/console/depthCameras.wav',
    },
  ]);

  const ref = useRef();

  const [droppedBoxNames, setDroppedBoxNames] = useState([]);
  const [draggedItem, setDraggedItem] = useState(null);
  const [currentAudio, setCurrentAudio] = useState('audio/console/intro.wav');
  const [showInstruction, setShowInstruction] = useState(true);

  const isDropped = (type) => droppedBoxNames.indexOf(type) > -1;

  const handleDrop = useCallback(
    (_, item) => {
      const { type, audio } = item;
      setDroppedBoxNames(
        update(droppedBoxNames, type ? { $push: [type] } : { $push: [] })
      );
      setCurrentAudio(audio);
    },
    [droppedBoxNames]
  );

  useEffect(() => {
    if (ref?.current && currentAudio) {
      ref.current.play();
    }
  }, [ref, currentAudio]);

  useEffect(() => {
    if (droppedBoxNames?.length === boxes.length) onComplete();
  }, [droppedBoxNames, boxes]);

  return (
    <DndProvider
      backend={TouchBackend}
      options={{
        enableMouseEvents: !hasNative,
        enableTouchEvents: true,
      }}>
      <div className='console'>
        {currentAudio && <audio ref={ref} src={currentAudio} />}
        <div
          className='console__dustbins'
          style={{ overflow: 'hidden', clear: 'both' }}>
          {dustbins.map(({ accepts, icon, width, height, x, y }, index) => (
            <Dustbin
              key={index}
              accept={accepts}
              width={width}
              height={height}
              x={x}
              y={y}
              icon={icon}
              onDrop={(item) => handleDrop(index, item)}
              dragged={draggedItem}
            />
          ))}

          {showInstruction && (
            <span
              style={{
                position: 'fixed',
                top: (window.innerWidth * 400) / base,
                left: isMobile ? 0 : (multiplier * 196) / base,
                right: isMobile ? 0 : 'auto',
                margin: 'auto',
              }}
              className='console__instruction'>
              Drag and drop items from the {isMobile ? 'bottom' : 'right'}{' '}
              <br /> to build the Training Console.
            </span>
          )}

          <BaseImages active={droppedBoxNames} />
        </div>

        <div
          className='console__boxes'
          style={{ overflow: 'hidden', clear: 'both' }}>
          {boxes.map(({ name, desc, type, icon, audio }, index) => (
            <Box
              key={index}
              name={name}
              desc={desc}
              type={type}
              audio={audio}
              icon={icon}
              isDropped={isDropped(type)}
              draggedItem={(type) => {
                setDraggedItem(type);
                type && setShowInstruction(false);
              }}
            />
          ))}
        </div>

        <Preview />
      </div>
    </DndProvider>
  );
});
