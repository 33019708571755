import React, { useState, useRef, useEffect, useContext, useMemo } from 'react';
import { useClickOutside } from 'js/hooks/useClickOutside';
import { DataContext } from 'js/context';

import 'js/components/ContactUs/styles.scss';

const exceptions = [3, 12, 13, 14, 15];

const ContactUs = () => {
  const [show, setShow] = useState(false);
  const [showAdditional, setShowAdditional] = useState(false);

  const { data } = useContext(DataContext);

  const clickRef = useRef();

  useClickOutside(clickRef, () => {
    setShow(false);
    setShowAdditional(false);
  });

  const exceptionsCompleted = useMemo(
    () => exceptions.every((element) => data.finished.includes(element)),
    [data.finished]
  );

  const totalFinished = useMemo(
    () =>
      data.finished?.filter((e) => !exceptions.includes(e)).length +
      (exceptionsCompleted ? 1 : 0),
    [data.finished, exceptionsCompleted]
  );

  useEffect(() => {
    if (totalFinished === 3) {
      setShow(true);
      setShowAdditional(true);
    }
  }, [data, totalFinished]);

  return (
    <div ref={clickRef} onMouseLeave={() => setShow(false)} className='contact'>
      <div
        className='toggle'
        style={{
          opacity: show ? 1 : 0,
          zIndex: show ? 1 : -9,
          display: show ? 'block' : 'none',
        }}>
        {totalFinished >= 3 && (
          <div className='item item--long item--meeting'>
            {showAdditional && (
              <span className='item__question'>
                Congratulations!
                <br /> You unlocked a meeting!
              </span>
            )}
            <a
              rel='noreferrer noopener'
              target='_blank'
              href='https://calendly.com/rafalzaremba/training-console-investment-meeting'>
              BOOK A MEETING
            </a>
          </div>
        )}
        <span className='item item--long'>
          CONTACT ME:{' '}
          <a href='mailto:rafal@highgrade.ninja'>rafal@highgrade.ninja</a>
        </span>

        <a className='item item--long' href='whitepaper.pdf' download>
          DOWNLOAD WHITEPAPER
        </a>
      </div>

      <button
        onMouseOver={() => setShow(true)}
        onClick={() => setShow(!show)}
        className='item item--main'>
        <img alt='info' src='images/info.png' />
        NEED MORE INFO?
      </button>
    </div>
  );
};

export default ContactUs;
