import React from "react";

export const lines = {
  0: [
    <path
      d="M432.5,671.2l-4.2-2.7l2.7-4.2l4.2,2.7L432.5,671.2z M423.2,665.4l-4.2-2.7l2.7-4.2l4.2,2.7L423.2,665.4z
  M413.9,659.5l-4.2-2.7l2.7-4.2l4.2,2.7L413.9,659.5z M404.6,653.7l-4.2-2.7l2.7-4.2l4.2,2.7L404.6,653.7z M395.2,647.8l-4.2-2.7
  l2.7-4.2l4.2,2.7L395.2,647.8z M385.9,642l-4.2-2.7l2.7-4.2l4.2,2.7L385.9,642z M376.6,636.1l-4.2-2.7l2.7-4.2l4.2,2.7L376.6,636.1z
  M367.3,630.3l-4.2-2.7l2.7-4.2l4.2,2.7L367.3,630.3z M358,624.4l-4.2-2.7l2.7-4.2l4.2,2.7L358,624.4z M348.7,618.6l-4.2-2.7
  l2.7-4.2l4.2,2.7L348.7,618.6z M339.3,612.7l-4.2-2.7l2.7-4.2l4.2,2.7L339.3,612.7z M330,606.9l-4.2-2.7l2.7-4.2l4.2,2.7L330,606.9z
  M320.7,601l-4.2-2.7l2.7-4.2l4.2,2.7L320.7,601z M311.4,595.2l-4.2-2.7l2.7-4.2l4.2,2.7L311.4,595.2z M302.1,589.3l-4.2-2.7
  l2.7-4.2l4.2,2.7L302.1,589.3z M292.8,583.5l-4.2-2.7l2.7-4.2l4.2,2.7L292.8,583.5z"
    />,
    <path
      id="Line_46"
      d="M438.7,675.2L436,671l4.2-2.7l2.7,4.2L438.7,675.2z M448,669.3l-2.7-4.2l4.2-2.7l2.7,4.2
  L448,669.3z M457.2,663.4l-2.7-4.2l4.2-2.7l2.7,4.2L457.2,663.4z M466.5,657.5l-2.7-4.2l4.2-2.7l2.7,4.2L466.5,657.5z M475.8,651.6
  l-2.7-4.2l4.2-2.7l2.7,4.2L475.8,651.6z M485.1,645.7l-2.7-4.2l4.2-2.7l2.7,4.2L485.1,645.7z M494.4,639.8l-2.7-4.2l4.2-2.7l2.7,4.2
  L494.4,639.8z M503.7,633.9l-2.7-4.2l4.2-2.7l2.7,4.2L503.7,633.9z M512.9,628l-2.7-4.2l4.2-2.7l2.7,4.2L512.9,628z M522.2,622.1
  l-2.7-4.2l4.2-2.7l2.7,4.2L522.2,622.1z M531.5,616.2l-2.7-4.2l4.2-2.7l2.7,4.2L531.5,616.2z M540.8,610.3l-2.7-4.2l4.2-2.7l2.7,4.2
  L540.8,610.3z"
    />,
    <path
      d="M824,784.2l-4.2-2.7l2.7-4.2l4.2,2.7L824,784.2z M814.7,778.4l-4.2-2.7l2.7-4.2l4.2,2.7L814.7,778.4z
  M805.4,772.5l-4.2-2.7l2.7-4.2l4.2,2.7L805.4,772.5z M796.1,766.6l-4.2-2.7l2.7-4.2l4.2,2.7L796.1,766.6z M786.8,760.8l-4.2-2.7
  l2.7-4.2l4.2,2.7L786.8,760.8z M777.5,754.9l-4.2-2.7l2.7-4.2l4.2,2.7L777.5,754.9z M768.2,749.1l-4.2-2.7l2.7-4.2l4.2,2.7
  L768.2,749.1z M758.8,743.2l-4.2-2.7l2.7-4.2l4.2,2.7L758.8,743.2z M749.5,737.3l-4.2-2.7l2.7-4.2l4.2,2.7L749.5,737.3z
  M740.2,731.5l-4.2-2.7l2.7-4.2l4.2,2.7L740.2,731.5z M730.9,725.6l-4.2-2.7l2.7-4.2l4.2,2.7L730.9,725.6z M721.6,719.8l-4.2-2.7
  l2.7-4.2l4.2,2.7L721.6,719.8z M712.3,713.9l-4.2-2.7l2.7-4.2l4.2,2.7L712.3,713.9z M703,708l-4.2-2.7l2.7-4.2l4.2,2.7L703,708z
  M693.7,702.2l-4.2-2.7l2.7-4.2l4.2,2.7L693.7,702.2z M684.4,696.3l-4.2-2.7l2.7-4.2l4.2,2.7L684.4,696.3z M675.1,690.4l-4.2-2.7
  l2.7-4.2l4.2,2.7L675.1,690.4z M665.8,684.6l-4.2-2.7l2.7-4.2l4.2,2.7L665.8,684.6z M656.4,678.7l-4.2-2.7l2.7-4.2l4.2,2.7
  L656.4,678.7z M647.1,672.9l-4.2-2.7l2.7-4.2l4.2,2.7L647.1,672.9z M637.8,667l-4.2-2.7l2.7-4.2l4.2,2.7L637.8,667z M628.5,661.1
  l-4.2-2.7l2.7-4.2l4.2,2.7L628.5,661.1z M619.2,655.3l-4.2-2.7l2.7-4.2l4.2,2.7L619.2,655.3z M609.9,649.4l-4.2-2.7l2.7-4.2l4.2,2.7
  L609.9,649.4z M600.6,643.5l-4.2-2.7l2.7-4.2l4.2,2.7L600.6,643.5z M591.3,637.7l-4.2-2.7l2.7-4.2l4.2,2.7L591.3,637.7z M582,631.8
  l-4.2-2.7l2.7-4.2l4.2,2.7L582,631.8z M572.7,626l-4.2-2.7l2.7-4.2l4.2,2.7L572.7,626z M563.4,620.1l-4.2-2.7l2.7-4.2l4.2,2.7
  L563.4,620.1z M554.1,614.2l-4.2-2.7l2.7-4.2l4.2,2.7L554.1,614.2z"
    />,
    <path
      d="M283.5,577.6l-4.2-2.7l2.7-4.2l4.2,2.7L283.5,577.6z M274.1,571.8l-4.2-2.7l2.7-4.2l4.2,2.7L274.1,571.8z
    M264.8,565.9l-4.2-2.7l2.7-4.2l4.2,2.7L264.8,565.9z M255.5,560.1l-4.2-2.7l2.6-4.2l4.2,2.7L255.5,560.1z M246.2,554.2l-4.2-2.7
    l2.7-4.2l4.2,2.7L246.2,554.2z M236.9,548.4l-4.2-2.7l2.7-4.2l4.2,2.7L236.9,548.4z M227.6,542.5l-4.2-2.7l2.7-4.2l4.2,2.7
    L227.6,542.5z M218.3,536.7L214,534l2.7-4.2l4.2,2.7L218.3,536.7z M208.9,530.8l-4.2-2.7l2.7-4.2l4.2,2.7L208.9,530.8z M199.6,525
    l-4.2-2.7l2.7-4.2l4.2,2.7L199.6,525z M190.3,519.1l-4.2-2.7l2.7-4.2l4.2,2.7L190.3,519.1z"
    />,
    <path
      d="M132.2,546.2l-2.7-4.2l4.2-2.7l2.7,4.2L132.2,546.2z M141.5,540.4l-2.6-4.2l4.2-2.7l2.7,4.2L141.5,540.4z
    M150.8,534.6l-2.7-4.2l4.2-2.7l2.7,4.2L150.8,534.6z M160.1,528.7l-2.7-4.2l4.2-2.7l2.7,4.2L160.1,528.7z M169.5,522.9l-2.7-4.2
    l4.2-2.6l2.7,4.2L169.5,522.9z M178.8,517l-2.7-4.2l4.2-2.7l2.7,4.2L178.8,517z"
    />,
  ],
  1: [
    <path
      id="Line_52"
      d="M102,540.2l-4.2-2.7l2.7-4.2l4.2,2.7L102,540.2z M92.7,534.4l-4.2-2.7l2.7-4.2l4.2,2.7L92.7,534.4
      z M83.4,528.5l-4.2-2.7l2.7-4.2l4.2,2.7L83.4,528.5z M74.1,522.7l-4.2-2.7l2.7-4.2l4.2,2.7L74.1,522.7z M64.8,516.8l-4.2-2.7
      l2.7-4.2l4.2,2.7L64.8,516.8z M55.4,511l-4.2-2.7l2.7-4.2l4.2,2.7L55.4,511z M46.1,505.1l-4.2-2.7l2.7-4.2l4.2,2.7L46.1,505.1z
      M36.8,499.2l-4.2-2.7l2.7-4.2l4.2,2.7L36.8,499.2z M27.5,493.4l-4.2-2.7l2.7-4.2l4.2,2.7L27.5,493.4z M18.2,487.5l-4.2-2.7l2.7-4.2
      l4.2,2.7L18.2,487.5z M8.9,481.7L5,479.2l2.7-4.2l3.9,2.4L8.9,481.7z"
    />,
    <path
      d="M2.7,476.2L0,472l4.2-2.7l2.7,4.2L2.7,476.2z M12,470.4l-2.7-4.2l4.2-2.7l2.7,4.2L12,470.4z M21.3,464.5
      l-2.7-4.2l4.2-2.7l2.7,4.2L21.3,464.5z M30.6,458.7l-2.7-4.2l4.2-2.7l2.7,4.2L30.6,458.7z M39.9,452.8l-2.7-4.2l4.2-2.7l2.7,4.2
      L39.9,452.8z M49.2,447l-2.7-4.2l4.2-2.7l2.7,4.2L49.2,447z M58.5,441.1l-2.7-4.2l4.2-2.7l2.7,4.2L58.5,441.1z M67.9,435.3l-2.7-4.2
      l4.2-2.7l2.7,4.2L67.9,435.3z M77.2,429.4l-2.7-4.2l4.2-2.7l2.7,4.2L77.2,429.4z M86.5,423.6l-2.7-4.2l4.2-2.7l2.7,4.2L86.5,423.6z
      M95.8,417.7l-2.7-4.2l4.2-2.7l2.7,4.2L95.8,417.7z M105.1,411.9l-2.7-4.2l4.2-2.7l2.7,4.2L105.1,411.9z M114.4,406l-2.7-4.2
      l4.2-2.7l2.7,4.2L114.4,406z M123.7,400.1l-2.7-4.2l4.2-2.7l2.7,4.2L123.7,400.1z M133.1,394.3l-2.7-4.2l4.2-2.7l2.7,4.2
      L133.1,394.3z M142.4,388.4l-2.7-4.2l4.2-2.7l2.7,4.2L142.4,388.4z M151.7,382.6l-2.7-4.2l4.2-2.7l2.7,4.2L151.7,382.6z M161,376.7
      l-2.7-4.2l4.2-2.7l2.7,4.2L161,376.7z M170.3,370.9l-2.7-4.2l4.2-2.7l2.7,4.2L170.3,370.9z"
    />,
  ],
  2: [
    <path
      d="M179.6,365l-2.7-4.2l4.2-2.7l2.7,4.2L179.6,365z M188.9,359.2l-2.7-4.2l4.2-2.7l2.7,4.2L188.9,359.2z
        M198.3,353.3l-2.7-4.2l4.2-2.7l2.7,4.2L198.3,353.3z M207.6,347.5l-2.7-4.2l4.2-2.7l2.7,4.2L207.6,347.5z M216.9,341.6l-2.7-4.2
        l4.2-2.7l2.7,4.2L216.9,341.6z M226.2,335.8l-2.7-4.2l4.2-2.7l2.7,4.2L226.2,335.8z M235.5,329.9l-2.7-4.2l4.2-2.7l2.7,4.2
        L235.5,329.9z M244.8,324.1l-2.7-4.2l4.2-2.7l2.7,4.2L244.8,324.1z M254.1,318.2l-2.7-4.2l4.2-2.7l2.7,4.2L254.1,318.2z
        M263.5,312.4l-2.7-4.2l4.2-2.7l2.7,4.2L263.5,312.4z M272.8,306.5l-2.7-4.2l4.2-2.7l2.7,4.2L272.8,306.5z M282.1,300.6l-2.7-4.2
        l4.2-2.7l2.7,4.2L282.1,300.6z M291.4,294.8l-2.7-4.2l4.2-2.7l2.7,4.2L291.4,294.8z M300.7,288.9l-2.7-4.2l4.2-2.7l2.7,4.2
        L300.7,288.9z M310,283.1l-2.7-4.2l4.2-2.7l2.7,4.2L310,283.1z M319.3,277.2l-2.7-4.2l4.2-2.7l2.7,4.2L319.3,277.2z M328.6,271.4
        l-2.7-4.2l4.2-2.7l2.7,4.2L328.6,271.4z"
    />,
    <path
      d="M332.2,266.4l-4.2-2.7l2.7-4.2l4.2,2.7L332.2,266.4z M322.9,260.5l-4.2-2.7l2.7-4.2l4.2,2.7L322.9,260.5z
        M313.6,254.7l-4.2-2.7l2.7-4.2l4.2,2.7L313.6,254.7z M304.3,248.8l-4.2-2.7l2.7-4.2l4.2,2.7L304.3,248.8z M295.1,242.9l-4.2-2.7
        l2.7-4.2l4.2,2.7L295.1,242.9z M285.8,237l-4.2-2.7l2.7-4.2l4.2,2.7L285.8,237z M276.5,231.1l-4.2-2.7l2.7-4.2l4.2,2.7L276.5,231.1z
        M267.2,225.2l-3.2-2l2.7-4.2l3.2,2L267.2,225.2z"
    />,
    <path
      d="M284.1,444.2l-4.2-2.7l2.7-4.2l4.2,2.7L284.1,444.2z M274.8,438.4l-4.2-2.7l2.7-4.2l4.2,2.7L274.8,438.4z
        M265.5,432.5l-4.2-2.7l2.7-4.2l4.2,2.7L265.5,432.5z M256.2,426.7L252,424l2.7-4.2l4.2,2.7L256.2,426.7z M246.9,420.8l-4.2-2.7
        l2.7-4.2l4.2,2.7L246.9,420.8z M237.6,414.9l-4.2-2.7l2.7-4.2l4.2,2.7L237.6,414.9z M228.3,409.1l-4.2-2.7l2.7-4.2l4.2,2.7
        L228.3,409.1z M219,403.2l-4.2-2.7l2.7-4.2l4.2,2.7L219,403.2z M209.7,397.3l-4.2-2.7l2.7-4.2l4.2,2.7L209.7,397.3z M200.4,391.5
        l-4.2-2.7l2.7-4.2l4.2,2.7L200.4,391.5z M191.1,385.6l-4.2-2.7l2.7-4.2l4.2,2.7L191.1,385.6z"
    />,
    <path
      d="M377.2,502.9l-4.2-2.7l2.7-4.2l4.2,2.7L377.2,502.9z M367.9,497l-4.2-2.7l2.7-4.2l4.2,2.7L367.9,497z
        M358.6,491.1l-4.2-2.7l2.7-4.2l4.2,2.7L358.6,491.1z M349.3,485.3l-4.2-2.7l2.7-4.2l4.2,2.7L349.3,485.3z M340,479.4l-4.2-2.7
        l2.7-4.2l4.2,2.7L340,479.4z M330.7,473.6l-4.2-2.7l2.7-4.2l4.2,2.7L330.7,473.6z M321.4,467.7l-4.2-2.7l2.7-4.2l4.2,2.7
        L321.4,467.7z M312.1,461.8l-4.2-2.7l2.7-4.2l4.2,2.7L312.1,461.8z M302.7,456l-4.2-2.7l2.7-4.2l4.2,2.7L302.7,456z M293.4,450.1
        l-4.2-2.7l2.7-4.2l4.2,2.7L293.4,450.1z"
    />,
    <path
      d="M384.7,500.2L382,496l4.2-2.7l2.7,4.2L384.7,500.2z M394,494.4l-2.7-4.2l4.2-2.7l2.7,4.2L394,494.4z
        M403.3,488.6l-2.7-4.2l4.2-2.7l2.7,4.2L403.3,488.6z M412.6,482.7l-2.7-4.2l4.2-2.7l2.7,4.2L412.6,482.7z M422,476.9l-2.7-4.2
        l4.2-2.7l2.7,4.2L422,476.9z M431.3,471.1l-2.7-4.2l4.2-2.7l2.7,4.2L431.3,471.1z M440.6,465.2L438,461l4.2-2.7l2.7,4.2L440.6,465.2
        z M449.9,459.4l-2.7-4.2l4.2-2.7l2.7,4.2L449.9,459.4z M459.3,453.6l-2.7-4.2l4.2-2.7l2.7,4.2L459.3,453.6z M468.6,447.7l-2.6-4.2
        l4.2-2.7l2.7,4.2L468.6,447.7z M477.9,441.9l-2.7-4.2l4.2-2.7l2.7,4.2L477.9,441.9z M487.2,436l-2.7-4.2l4.2-2.7l2.7,4.2L487.2,436z
        M496.6,430.2l-2.7-4.2l4.2-2.7l2.7,4.2L496.6,430.2z"
    />,
    <path
      d="M505.9,424.4l-2.6-4.2l4.2-2.7l2.7,4.2L505.9,424.4z M515.2,418.5l-2.7-4.2l4.2-2.7l2.7,4.2L515.2,418.5z
        M524.5,412.7l-2.7-4.2l4.2-2.7l2.7,4.2L524.5,412.7z M533.9,406.9l-2.7-4.2l4.2-2.7l2.7,4.2L533.9,406.9z M543.2,401l-2.7-4.2
        l4.2-2.7l2.7,4.2L543.2,401z"
    />,
    <path
      d="M470.3,561.5l-4.2-2.7l2.7-4.2l4.2,2.7L470.3,561.5z M461,555.6l-4.2-2.7l2.7-4.2l4.2,2.7L461,555.6z
      M451.7,549.8l-4.2-2.7l2.7-4.2l4.2,2.7L451.7,549.8z M442.4,543.9l-4.2-2.7l2.7-4.2l4.2,2.7L442.4,543.9z M433.1,538l-4.2-2.7
      l2.7-4.2l4.2,2.7L433.1,538z M423.7,532.2l-4.2-2.7l2.7-4.2l4.2,2.7L423.7,532.2z M414.4,526.3l-4.2-2.7l2.7-4.2l4.2,2.7
      L414.4,526.3z M405.1,520.4l-4.2-2.7l2.7-4.2l4.2,2.7L405.1,520.4z M395.8,514.6l-4.2-2.7l2.7-4.2l4.2,2.7L395.8,514.6z
      M386.5,508.7l-4.2-2.7l2.7-4.2l4.2,2.7L386.5,508.7z"
    />,
    <path
      d="M484.7,560.7l-2.7-4.2l4.2-2.7l2.7,4.2L484.7,560.7z M494,554.9l-2.7-4.2l4.2-2.7l2.7,4.2L494,554.9z
      M503.3,549l-2.7-4.2l4.2-2.7l2.7,4.2L503.3,549z M512.6,543.2L510,539l4.2-2.7l2.7,4.2L512.6,543.2z M521.9,537.4l-2.7-4.2l4.2-2.7
      l2.7,4.2L521.9,537.4z M531.2,531.5l-2.7-4.2l4.2-2.7l2.7,4.2L531.2,531.5z M540.6,525.7l-2.7-4.2l4.2-2.7l2.7,4.2L540.6,525.7z
      M549.9,519.8l-2.7-4.2l4.2-2.7l2.7,4.2L549.9,519.8z M559.2,514l-2.7-4.2l4.2-2.7l2.7,4.2L559.2,514z M568.5,508.1l-2.7-4.2
      l4.2-2.7l2.7,4.2L568.5,508.1z"
    />,
    <path
      id="Line_42"
      d="M625,537.2l-4.2-2.7l2.7-4.2l4.2,2.7L625,537.2z M615.7,531.3l-4.2-2.7l2.7-4.2l4.2,2.7
      L615.7,531.3z M606.4,525.4l-4.2-2.7l2.7-4.2l4.2,2.7L606.4,525.4z M597.1,519.5l-4.2-2.7l2.7-4.2l4.2,2.7L597.1,519.5z
      M587.8,513.7l-4.2-2.7l2.7-4.2l4.2,2.7L587.8,513.7z M578.6,507.8l-4.2-2.7l2.7-4.2l4.2,2.7L578.6,507.8z"
    />,
  ],
  3: [
    <path
      d="M505.9,424.4l-2.6-4.2l4.2-2.7l2.7,4.2L505.9,424.4z M515.2,418.5l-2.7-4.2l4.2-2.7l2.7,4.2L515.2,418.5z
        M524.5,412.7l-2.7-4.2l4.2-2.7l2.7,4.2L524.5,412.7z M533.9,406.9l-2.7-4.2l4.2-2.7l2.7,4.2L533.9,406.9z M543.2,401l-2.7-4.2
        l4.2-2.7l2.7,4.2L543.2,401z"
    />,
    <path
      d="M652.4,455.5l-2.7-4.2l4.2-2.7l2.7,4.2L652.4,455.5z M661.7,449.7l-2.7-4.2l4.2-2.7l2.7,4.2L661.7,449.7z
 M671,443.8l-2.7-4.2l4.2-2.7l2.7,4.2L671,443.8z M680.4,438l-2.7-4.2l4.2-2.7l2.7,4.2L680.4,438z M689.7,432.1l-2.7-4.2l4.2-2.7
 l2.7,4.2L689.7,432.1z M699,426.3l-2.7-4.2l4.2-2.7l2.7,4.2L699,426.3z M708.3,420.5l-2.7-4.2l4.2-2.7l2.7,4.2L708.3,420.5z
 M717.6,414.6l-2.7-4.2l4.2-2.7l2.7,4.2L717.6,414.6z M726.9,408.8l-2.7-4.2l4.2-2.7l2.7,4.2L726.9,408.8z M736.3,402.9l-2.7-4.2
 l4.2-2.7l2.7,4.2L736.3,402.9z M745.6,397.1l-2.7-4.2l4.2-2.7l2.7,4.2L745.6,397.1z M754.9,391.2l-2.7-4.2l4.2-2.7l2.7,4.2
 L754.9,391.2z"
    ></path>,
    <path
      id="Line_45"
      d="M495,426.7l-4.2-2.7l2.7-4.2l4.2,2.7L495,426.7z M485.7,420.9l-4.2-2.7l2.7-4.2l4.2,2.7
        L485.7,420.9z M476.4,415l-4.2-2.7l2.7-4.2l4.2,2.7L476.4,415z M467.1,409.1l-4.2-2.7l2.7-4.2l4.2,2.7L467.1,409.1z M457.8,403.3
        l-4.2-2.7l2.7-4.2l4.2,2.7L457.8,403.3z M448.5,397.4l-4.2-2.7l2.7-4.2l4.2,2.7L448.5,397.4z M439.2,391.5l-4.2-2.7l2.7-4.2l4.2,2.7
        L439.2,391.5z M429.9,385.7l-4.3-2.7l2.7-4.2l4.2,2.7L429.9,385.7z M420.6,379.8l-4.3-2.7l2.7-4.2l4.2,2.7L420.6,379.8z
        M411.3,373.9l-4.2-2.7l2.7-4.2l4.2,2.7L411.3,373.9z M402,368l-4.2-2.7l2.7-4.2l4.2,2.7L402,368z M392.7,362.2l-4.2-2.7l2.7-4.2
        l4.2,2.7L392.7,362.2z M383.4,356.3l-4.2-2.7l2.7-4.2l4.2,2.7L383.4,356.3z M374.1,350.4l-4.2-2.7l2.7-4.2l4.2,2.7L374.1,350.4z
        M364.8,344.6l-4.2-2.7l2.7-4.2l4.2,2.7L364.8,344.6z M355.5,338.7l-4.3-2.7l2.7-4.2l4.2,2.7L355.5,338.7z M346.1,332.8l-4.1-2.6
        l2.7-4.2l4.1,2.6L346.1,332.8z"
    />,
    <path
      id="Line_49"
      d="M344.7,331.2L342,327l4.2-2.7l2.7,4.2L344.7,331.2z M354,325.3l-2.7-4.2l4.2-2.7l2.7,4.2
        L354,325.3z M363.2,319.4l-2.7-4.2l4.2-2.7l2.7,4.2L363.2,319.4z M372.5,313.5l-2.7-4.2l4.2-2.7l2.7,4.2L372.5,313.5z M381.8,307.6
        l-2.7-4.2l4.2-2.7l2.7,4.2L381.8,307.6z"
    />,
    <path
      d="M388,301.7l-4.2-2.7l2.7-4.2l4.2,2.7L388,301.7z M378.7,295.8l-4.2-2.7l2.7-4.2l4.2,2.7L378.7,295.8z
        M369.4,290l-4.2-2.7l2.7-4.2l4.2,2.7L369.4,290z M360.1,284.1l-4.2-2.7l2.7-4.2l4.2,2.7L360.1,284.1z M350.8,278.2l-4.2-2.7
        l2.7-4.2l4.2,2.7L350.8,278.2z M341.5,272.3l-4.2-2.7l2.7-4.2l4.2,2.7L341.5,272.3z"
    />,
    <path
      d="M332.2,266.4l-4.2-2.7l2.7-4.2l4.2,2.7L332.2,266.4z M322.9,260.5l-4.2-2.7l2.7-4.2l4.2,2.7L322.9,260.5z
        M313.6,254.7l-4.2-2.7l2.7-4.2l4.2,2.7L313.6,254.7z M304.3,248.8l-4.2-2.7l2.7-4.2l4.2,2.7L304.3,248.8z M295.1,242.9l-4.2-2.7
        l2.7-4.2l4.2,2.7L295.1,242.9z M285.8,237l-4.2-2.7l2.7-4.2l4.2,2.7L285.8,237z M276.5,231.1l-4.2-2.7l2.7-4.2l4.2,2.7L276.5,231.1z
        M267.2,225.2l-3.2-2l2.7-4.2l3.2,2L267.2,225.2z"
    />,
    <path
      id="Line_41"
      d="M647,456.2l-4.2-2.7l2.7-4.2l4.2,2.6L647,456.2z M637.7,450.4l-4.2-2.6l2.7-4.2l4.2,2.6
        L637.7,450.4z M628.3,444.6l-4.2-2.6l2.7-4.2l4.2,2.7L628.3,444.6z M619,438.7l-4.2-2.6l2.7-4.2l4.2,2.6L619,438.7z M609.7,432.9
        l-4.2-2.6l2.7-4.2l4.2,2.7L609.7,432.9z M600.4,427.1l-4.2-2.6l2.7-4.2l4.2,2.6L600.4,427.1z M591,421.3l-4.2-2.6l2.7-4.2l4.2,2.6
        L591,421.3z M581.7,415.4l-4.2-2.7l2.7-4.2l4.2,2.6L581.7,415.4z M572.4,409.6l-4.2-2.6l2.7-4.2l4.2,2.6L572.4,409.6z M563.1,403.8
        l-4-2.5l2.7-4.2l4,2.5L563.1,403.8z"
    />,
    <path
      d="M577.8,502.3l-2.7-4.2l4.2-2.7l2.7,4.2L577.8,502.3z M587.2,496.4l-2.7-4.2l4.2-2.7l2.7,4.2L587.2,496.4z
        M596.5,490.6l-2.7-4.2l4.2-2.7l2.7,4.2L596.5,490.6z M605.8,484.8l-2.7-4.2l4.2-2.7l2.7,4.2L605.8,484.8z M615.1,478.9l-2.7-4.2
        l4.2-2.7l2.7,4.2L615.1,478.9z M624.4,473.1l-2.7-4.2l4.2-2.7l2.7,4.2L624.4,473.1z M633.8,467.2l-2.7-4.2l4.2-2.7l2.7,4.2
        L633.8,467.2z M643.1,461.4l-2.7-4.2l4.2-2.7l2.7,4.2L643.1,461.4z"
    />,
    <path
      id="Line_42"
      d="M625,537.2l-4.2-2.7l2.7-4.2l4.2,2.7L625,537.2z M615.7,531.3l-4.2-2.7l2.7-4.2l4.2,2.7
        L615.7,531.3z M606.4,525.4l-4.2-2.7l2.7-4.2l4.2,2.7L606.4,525.4z M597.1,519.5l-4.2-2.7l2.7-4.2l4.2,2.7L597.1,519.5z
        M587.8,513.7l-4.2-2.7l2.7-4.2l4.2,2.7L587.8,513.7z M578.6,507.8l-4.2-2.7l2.7-4.2l4.2,2.7L578.6,507.8z"
    />,
    <path
      id="Line_42"
      d="M625,537.2l-4.2-2.7l2.7-4.2l4.2,2.7L625,537.2z M615.7,531.3l-4.2-2.7l2.7-4.2l4.2,2.7
        L615.7,531.3z M606.4,525.4l-4.2-2.7l2.7-4.2l4.2,2.7L606.4,525.4z M597.1,519.5l-4.2-2.7l2.7-4.2l4.2,2.7L597.1,519.5z
        M587.8,513.7l-4.2-2.7l2.7-4.2l4.2,2.7L587.8,513.7z M578.6,507.8l-4.2-2.7l2.7-4.2l4.2,2.7L578.6,507.8z"
    />,
    <path
      d="M764.2,385.4l-2.7-4.2l4.2-2.7l2.7,4.2L764.2,385.4z M773.5,379.5l-2.7-4.2l4.2-2.7l2.7,4.2L773.5,379.5z
        M782.9,373.7l-2.7-4.2l4.2-2.6l2.7,4.2L782.9,373.7z M792.2,367.9l-2.7-4.2l4.2-2.7l2.7,4.2L792.2,367.9z M801.5,362l-2.7-4.2
        l4.2-2.7l2.7,4.2L801.5,362z M810.8,356.2l-2.7-4.2l4.2-2.7l2.7,4.2L810.8,356.2z M820.1,350.3l-2.7-4.2l4.2-2.7l2.7,4.2
        L820.1,350.3z M829.5,344.5l-2.7-4.2l4.2-2.7l2.7,4.2L829.5,344.5z M838.8,338.6l-2.7-4.2l4.2-2.7l2.7,4.2L838.8,338.6z
        M848.1,332.8l-2.7-4.2l4.2-2.7l2.7,4.2L848.1,332.8z M857.4,326.9l-2.7-4.2l4.2-2.7l2.7,4.2L857.4,326.9z M866.7,321.1l-2.7-4.2
        l4.2-2.6l2.7,4.2L866.7,321.1z M876.1,315.3l-2.7-4.2l4.2-2.6l2.7,4.2L876.1,315.3z M885.4,309.4l-2.7-4.2l0.3-0.2l2.7,4.2
        L885.4,309.4z"
    />,
    <path
      d="M806.6,425.2l-4.2-2.7l2.7-4.2l4.2,2.7L806.6,425.2z M797.3,419.4l-4.2-2.7l2.7-4.2l4.2,2.7L797.3,419.4z
 M788,413.5l-4.2-2.7l2.7-4.2l4.2,2.7L788,413.5z M778.7,407.7l-4.2-2.7l2.7-4.2l4.2,2.7L778.7,407.7z M769.4,401.8l-4.2-2.7
 l2.7-4.2l4.2,2.7L769.4,401.8z M760.1,396l-4.2-2.7l2.7-4.2l4.2,2.7L760.1,396z"
    />,
    <path
      d="M937,507.2l-4.2-2.7l2.7-4.2l4.2,2.7L937,507.2z M927.7,501.4l-4.2-2.7l2.7-4.2l4.2,2.7L927.7,501.4z
 M918.4,495.5l-4.2-2.7l2.7-4.2l4.2,2.7L918.4,495.5z M909.1,489.7l-4.2-2.7l2.7-4.2l4.2,2.7L909.1,489.7z M899.8,483.8l-4.2-2.7
 l2.7-4.2l4.2,2.7L899.8,483.8z M890.4,477.9l-4.2-2.7l2.7-4.2l4.2,2.7L890.4,477.9z M881.1,472.1l-4.2-2.7l2.7-4.2l4.2,2.7
 L881.1,472.1z M871.8,466.2l-4.2-2.7l2.7-4.2l4.2,2.7L871.8,466.2z M862.5,460.4l-4.2-2.7l2.7-4.2l4.2,2.7L862.5,460.4z
 M853.2,454.5l-4.2-2.7l2.7-4.2l4.2,2.7L853.2,454.5z M843.9,448.7l-4.2-2.7l2.7-4.2l4.2,2.7L843.9,448.7z M834.6,442.8l-4.2-2.7
 l2.7-4.2l4.2,2.7L834.6,442.8z M825.3,437l-4.2-2.7l2.7-4.2l4.2,2.7L825.3,437z M816,431.1l-4.2-2.7l2.7-4.2l4.2,2.7L816,431.1z"
    />,
    <path
      d="M384.7,500.2L382,496l4.2-2.7l2.7,4.2L384.7,500.2z M394,494.4l-2.7-4.2l4.2-2.7l2.7,4.2L394,494.4z
      M403.3,488.6l-2.7-4.2l4.2-2.7l2.7,4.2L403.3,488.6z M412.6,482.7l-2.7-4.2l4.2-2.7l2.7,4.2L412.6,482.7z M422,476.9l-2.7-4.2
      l4.2-2.7l2.7,4.2L422,476.9z M431.3,471.1l-2.7-4.2l4.2-2.7l2.7,4.2L431.3,471.1z M440.6,465.2L438,461l4.2-2.7l2.7,4.2L440.6,465.2
      z M449.9,459.4l-2.7-4.2l4.2-2.7l2.7,4.2L449.9,459.4z M459.3,453.6l-2.7-4.2l4.2-2.7l2.7,4.2L459.3,453.6z M468.6,447.7l-2.6-4.2
      l4.2-2.7l2.7,4.2L468.6,447.7z M477.9,441.9l-2.7-4.2l4.2-2.7l2.7,4.2L477.9,441.9z M487.2,436l-2.7-4.2l4.2-2.7l2.7,4.2L487.2,436z
      M496.6,430.2l-2.7-4.2l4.2-2.7l2.7,4.2L496.6,430.2z"
    />,
    <path
      d="M377.2,502.9l-4.2-2.7l2.7-4.2l4.2,2.7L377.2,502.9z M367.9,497l-4.2-2.7l2.7-4.2l4.2,2.7L367.9,497z
      M358.6,491.1l-4.2-2.7l2.7-4.2l4.2,2.7L358.6,491.1z M349.3,485.3l-4.2-2.7l2.7-4.2l4.2,2.7L349.3,485.3z M340,479.4l-4.2-2.7
      l2.7-4.2l4.2,2.7L340,479.4z M330.7,473.6l-4.2-2.7l2.7-4.2l4.2,2.7L330.7,473.6z M321.4,467.7l-4.2-2.7l2.7-4.2l4.2,2.7
      L321.4,467.7z M312.1,461.8l-4.2-2.7l2.7-4.2l4.2,2.7L312.1,461.8z M302.7,456l-4.2-2.7l2.7-4.2l4.2,2.7L302.7,456z M293.4,450.1
      l-4.2-2.7l2.7-4.2l4.2,2.7L293.4,450.1z"
    />,
    <path
      d="M188.1,511.2l-2.7-4.2l4.2-2.6l2.7,4.2L188.1,511.2z M197.4,505.4l-2.7-4.2l4.2-2.7l2.6,4.2L197.4,505.4z
      M206.7,499.5l-2.7-4.2l4.2-2.7l2.7,4.2L206.7,499.5z M216.1,493.7l-2.7-4.2l4.2-2.7l2.7,4.2L216.1,493.7z M225.4,487.9l-2.7-4.2
      l4.2-2.7l2.7,4.2L225.4,487.9z M234.7,482l-2.7-4.2l4.2-2.7l2.7,4.2L234.7,482z M244,476.2l-2.7-4.2l4.2-2.7l2.7,4.2L244,476.2z
      M253.4,470.3l-2.7-4.2l4.2-2.7l2.7,4.2L253.4,470.3z M262.7,464.5l-2.7-4.2l4.2-2.7l2.7,4.2L262.7,464.5z M272,458.7l-2.7-4.2
      l4.2-2.7l2.7,4.2L272,458.7z M281.3,452.8l-2.7-4.2l4.2-2.7l2.7,4.2L281.3,452.8z"
    />,
    <path
      d="M132.2,546.2l-2.7-4.2l4.2-2.7l2.7,4.2L132.2,546.2z M141.5,540.4l-2.6-4.2l4.2-2.7l2.7,4.2L141.5,540.4z
      M150.8,534.6l-2.7-4.2l4.2-2.7l2.7,4.2L150.8,534.6z M160.1,528.7l-2.7-4.2l4.2-2.7l2.7,4.2L160.1,528.7z M169.5,522.9l-2.7-4.2
      l4.2-2.6l2.7,4.2L169.5,522.9z M178.8,517l-2.7-4.2l4.2-2.7l2.7,4.2L178.8,517z"
    />,
  ],
  4: [
    <path
      d="M647.7,529.5l-2.7-4.2l4.2-2.7l2.7,4.2L647.7,529.5z M657,523.6l-2.7-4.2l4.2-2.7l2.7,4.2L657,523.6z
      M666.3,517.8l-2.7-4.2l4.2-2.7l2.7,4.2L666.3,517.8z M675.6,512l-2.7-4.2l4.2-2.7l2.7,4.2L675.6,512z M684.9,506.1l-2.7-4.2
      l4.2-2.7l2.7,4.2L684.9,506.1z M694.3,500.3l-2.7-4.2l4.2-2.7l2.7,4.2L694.3,500.3z M703.6,494.5l-2.7-4.2l4.2-2.7l2.7,4.2
      L703.6,494.5z M712.9,488.6l-2.7-4.2l4.2-2.7l2.7,4.2L712.9,488.6z M722.2,482.8l-2.7-4.2l4.2-2.7l2.7,4.2L722.2,482.8z
      M731.6,476.9l-2.7-4.2l4.2-2.7l2.7,4.2L731.6,476.9z M740.9,471.1l-2.7-4.2l4.2-2.7l2.7,4.2L740.9,471.1z M750.2,465.3l-2.7-4.2
      l4.2-2.7l2.7,4.2L750.2,465.3z M759.5,459.4l-2.7-4.2l4.2-2.7l2.7,4.2L759.5,459.4z M768.9,453.6l-2.7-4.2l4.2-2.7l2.7,4.2
      L768.9,453.6z M778.2,447.8l-2.7-4.2l4.2-2.7l2.7,4.2L778.2,447.8z M787.5,441.9l-2.7-4.2l4.2-2.7l2.7,4.2L787.5,441.9z
      M796.8,436.1l-2.7-4.2l4.2-2.7l2.7,4.2L796.8,436.1z M806.1,430.2l-2.7-4.2l4.2-2.7l2.7,4.2L806.1,430.2z"
    />,
    <path
      d="M937,507.2l-4.2-2.7l2.7-4.2l4.2,2.7L937,507.2z M927.7,501.4l-4.2-2.7l2.7-4.2l4.2,2.7L927.7,501.4z
      M918.4,495.5l-4.2-2.7l2.7-4.2l4.2,2.7L918.4,495.5z M909.1,489.7l-4.2-2.7l2.7-4.2l4.2,2.7L909.1,489.7z M899.8,483.8l-4.2-2.7
      l2.7-4.2l4.2,2.7L899.8,483.8z M890.4,477.9l-4.2-2.7l2.7-4.2l4.2,2.7L890.4,477.9z M881.1,472.1l-4.2-2.7l2.7-4.2l4.2,2.7
      L881.1,472.1z M871.8,466.2l-4.2-2.7l2.7-4.2l4.2,2.7L871.8,466.2z M862.5,460.4l-4.2-2.7l2.7-4.2l4.2,2.7L862.5,460.4z
      M853.2,454.5l-4.2-2.7l2.7-4.2l4.2,2.7L853.2,454.5z M843.9,448.7l-4.2-2.7l2.7-4.2l4.2,2.7L843.9,448.7z M834.6,442.8l-4.2-2.7
      l2.7-4.2l4.2,2.7L834.6,442.8z M825.3,437l-4.2-2.7l2.7-4.2l4.2,2.7L825.3,437z M816,431.1l-4.2-2.7l2.7-4.2l4.2,2.7L816,431.1z"
    />,
    <path
      d="M815.5,424.4l-2.7-4.2l4.2-2.7l2.7,4.2L815.5,424.4z M824.8,418.6l-2.7-4.2l4.2-2.7l2.7,4.2L824.8,418.6z
 M834.1,412.7l-2.7-4.2l4.2-2.7l2.7,4.2L834.1,412.7z M843.4,406.9l-2.7-4.2l4.2-2.7l2.7,4.2L843.4,406.9z M852.8,401.1l-2.7-4.2
 l4.2-2.7l2.7,4.2L852.8,401.1z M862.1,395.2l-2.7-4.2l4.2-2.7l2.7,4.2L862.1,395.2z M871.4,389.4l-2.7-4.2l4.2-2.7l2.7,4.2
 L871.4,389.4z M880.7,383.5l-2.7-4.2l4.2-2.7l2.7,4.2L880.7,383.5z M890.1,377.7l-2.7-4.2l4.2-2.7l2.7,4.2L890.1,377.7z
 M899.4,371.9l-2.7-4.2l4.2-2.7l2.7,4.2L899.4,371.9z M908.7,366l-2.7-4.2l4.2-2.7l2.7,4.2L908.7,366z M918,360.2l-2.7-4.2l4.2-2.7
 l2.7,4.2L918,360.2z M927.3,354.3l-2.7-4.2l4.2-2.7l2.7,4.2L927.3,354.3z M936.7,348.5l-2.7-4.2l4.2-2.7l2.7,4.2L936.7,348.5z
 M946,342.7l-2.7-4.2l4.2-2.7l2.7,4.2L946,342.7z"
    ></path>,
    <path
      d="M937,507.2l-4.2-2.7l2.7-4.2l4.2,2.7L937,507.2z M927.7,501.4l-4.2-2.7l2.7-4.2l4.2,2.7L927.7,501.4z
      M918.4,495.5l-4.2-2.7l2.7-4.2l4.2,2.7L918.4,495.5z M909.1,489.7l-4.2-2.7l2.7-4.2l4.2,2.7L909.1,489.7z M899.8,483.8l-4.2-2.7
      l2.7-4.2l4.2,2.7L899.8,483.8z M890.4,477.9l-4.2-2.7l2.7-4.2l4.2,2.7L890.4,477.9z M881.1,472.1l-4.2-2.7l2.7-4.2l4.2,2.7
      L881.1,472.1z M871.8,466.2l-4.2-2.7l2.7-4.2l4.2,2.7L871.8,466.2z M862.5,460.4l-4.2-2.7l2.7-4.2l4.2,2.7L862.5,460.4z
      M853.2,454.5l-4.2-2.7l2.7-4.2l4.2,2.7L853.2,454.5z M843.9,448.7l-4.2-2.7l2.7-4.2l4.2,2.7L843.9,448.7z M834.6,442.8l-4.2-2.7
      l2.7-4.2l4.2,2.7L834.6,442.8z M825.3,437l-4.2-2.7l2.7-4.2l4.2,2.7L825.3,437z M816,431.1l-4.2-2.7l2.7-4.2l4.2,2.7L816,431.1z"
    />,
    <path
      d="M953,335.2l-4.2-2.7l2.7-4.2l4.2,2.7L953,335.2z M943.7,329.4l-4.2-2.7l2.7-4.2l4.2,2.7L943.7,329.4z
      M934.4,323.6l-4.2-2.7l2.7-4.2l4.2,2.7L934.4,323.6z M925,317.7l-4.2-2.7l2.7-4.2l4.2,2.7L925,317.7z M915.7,311.9l-4.2-2.7
      l2.7-4.2l4.2,2.7L915.7,311.9z M906.4,306l-4.2-2.7l2.7-4.2l4.2,2.7L906.4,306z M897.1,300.2l-4.2-2.7l2.7-4.2l4.2,2.7L897.1,300.2z
      "
    />,
  ],
  5: [
    <path
      d="M887.7,294.4l-4.2-2.7l2.7-4.2l4.2,2.7L887.7,294.4z M878.4,288.5l-4.2-2.7l2.7-4.2l4.2,2.7L878.4,288.5z
      M869.1,282.7l-4.2-2.7l2.7-4.2l4.2,2.7L869.1,282.7z M859.8,276.9l-4.2-2.7l2.7-4.2l4.2,2.7L859.8,276.9z M850.4,271l-4.2-2.7
      l2.7-4.2l4.2,2.7L850.4,271z M841.1,265.2l-4.2-2.7l2.7-4.2l4.2,2.7L841.1,265.2z M831.8,259.3l-4.2-2.7l2.7-4.2l4.2,2.7
      L831.8,259.3z M822.5,253.5l-4.2-2.7l2.7-4.2l4.2,2.7L822.5,253.5z M813.2,247.7L809,245l2.7-4.2l4.2,2.7L813.2,247.7z M803.8,241.8
      l-4.2-2.7l2.7-4.2l4.2,2.7L803.8,241.8z M794.5,236l-4.2-2.7l2.7-4.2l4.2,2.7L794.5,236z M785.2,230.2l-4.2-2.7l2.7-4.2l4.2,2.7
      L785.2,230.2z M775.9,224.3l-4.2-2.7l2.7-4.2l4.2,2.7L775.9,224.3z M766.5,218.5l-4.2-2.7l2.7-4.2l4.2,2.7L766.5,218.5z
      M757.2,212.6L753,210l2.6-4.2l4.2,2.7L757.2,212.6z M747.9,206.8l-4.2-2.7l2.7-4.2l4.2,2.7L747.9,206.8z"
    />,
    <path
      id="Line_40"
      d="M740.7,202.2L738,198l4.2-2.7l2.7,4.2L740.7,202.2z M749.9,196.3l-2.7-4.2l4.2-2.7l2.7,4.2
      L749.9,196.3z M759.2,190.3l-2.7-4.2l4.2-2.7l2.7,4.2L759.2,190.3z M768.4,184.4l-2.7-4.2l4.2-2.7l2.7,4.2L768.4,184.4z
      M777.7,178.4l-2.7-4.2l4.2-2.7l2.7,4.2L777.7,178.4z M786.9,172.4l-2.7-4.2l4.2-2.7l2.7,4.2L786.9,172.4z M796.2,166.5l-2.7-4.2
      l4.2-2.7l2.7,4.2L796.2,166.5z M805.4,160.5l-2.7-4.2l4.2-2.7l2.7,4.2L805.4,160.5z"
    />,
    <path
      id="Line_37"
      d="M811,157.2l-4.2-2.7l2.7-4.2l4.2,2.7L811,157.2z M801.7,151.4l-4.2-2.7l2.7-4.2l4.2,2.7
      L801.7,151.4z M792.4,145.5l-4.2-2.7l2.7-4.2l4.2,2.7L792.4,145.5z M783,139.7l-4.2-2.7l2.7-4.2l4.2,2.7L783,139.7z M773.7,133.9
      l-4.2-2.7l2.7-4.2l4.2,2.7L773.7,133.9z M764.4,128l-4.2-2.7l2.7-4.2l4.2,2.7L764.4,128z M755.1,122.2l-4.2-2.7l2.7-4.2l4.2,2.7
      L755.1,122.2z M745.8,116.3l-4.2-2.7l2.7-4.2l4.2,2.7L745.8,116.3z M736.4,110.5l-4.2-2.7l2.7-4.2l4.2,2.7L736.4,110.5z
      M727.1,104.6l-4.2-2.7l2.7-4.2l4.2,2.7L727.1,104.6z M717.8,98.8l-4.2-2.7l2.7-4.2l4.2,2.7L717.8,98.8z M708.5,93l-4.2-2.7l2.7-4.2
      l4.2,2.7L708.5,93z M699.2,87.1l-4.2-2.7l2.7-4.2l4.2,2.7L699.2,87.1z M689.8,81.3l-4.2-2.7l2.7-4.2l4.2,2.7L689.8,81.3z
      M680.5,75.4l-4.2-2.7l2.7-4.2l4.2,2.7L680.5,75.4z M671.2,69.6l-4.2-2.7l2.7-4.2l4.2,2.7L671.2,69.6z M661.9,63.7l-4.2-2.7l2.7-4.2
      l4.2,2.7L661.9,63.7z M652.6,57.9l-4.2-2.7L651,51l4.2,2.7L652.6,57.9z M643.3,52l-4.2-2.7l2.7-4.2l4.2,2.7L643.3,52z M633.9,46.2
      l-4.2-2.7l2.7-4.2l4.2,2.7L633.9,46.2z M624.6,40.4l-4.2-2.7l2.7-4.2l4.2,2.7L624.6,40.4z M615.3,34.5l-4.2-2.7l2.7-4.2l4.2,2.7
      L615.3,34.5z M606,28.7l-4.2-2.7l2.7-4.2l4.2,2.7L606,28.7z M596.7,22.8l-4.2-2.7l2.7-4.2l4.2,2.7L596.7,22.8z M587.3,17l-4.2-2.7
      l2.7-4.2l4.2,2.7L587.3,17z M578,11.1l-4.2-2.7l2.7-4.2l4.2,2.7L578,11.1z M568.7,5.3L567,4.2l2.7-4.2l1.7,1.1L568.7,5.3z"
    />,
    <path
      id="Line_43"
      d="M261.7,200.2L259,196l4.2-2.7l2.7,4.2L261.7,200.2z M271,194.4l-2.7-4.2l4.2-2.7l2.7,4.2
      L271,194.4z M280.3,188.5l-2.7-4.2l4.2-2.7l2.7,4.2L280.3,188.5z M289.6,182.7l-2.7-4.2l4.2-2.7l2.7,4.2L289.6,182.7z M298.9,176.8
      l-2.7-4.2l4.2-2.7l2.7,4.2L298.9,176.8z M308.2,171l-2.7-4.2l4.2-2.7l2.7,4.2L308.2,171z M317.5,165.1l-2.7-4.2l4.2-2.7l2.7,4.2
      L317.5,165.1z M326.9,159.3l-2.7-4.2l4.2-2.7l2.7,4.2L326.9,159.3z M336.2,153.4l-2.7-4.2l4.2-2.7l2.7,4.2L336.2,153.4z
      M345.5,147.6l-2.7-4.2l4.2-2.7l2.7,4.2L345.5,147.6z M354.8,141.7l-2.7-4.2l4.2-2.7l2.7,4.2L354.8,141.7z M364.1,135.9l-2.7-4.2
      l4.2-2.7l2.7,4.2L364.1,135.9z M373.4,130l-2.7-4.2l4.2-2.7l2.7,4.2L373.4,130z M382.7,124.2L380,120l4.2-2.7l2.7,4.2L382.7,124.2z
      M392.1,118.3l-2.7-4.2l4.2-2.7l2.7,4.2L392.1,118.3z M401.4,112.5l-2.7-4.2l4.2-2.7l2.7,4.2L401.4,112.5z M410.7,106.6l-2.7-4.2
      l4.2-2.7l2.7,4.2L410.7,106.6z M420,100.8l-2.7-4.2l4.2-2.7l2.7,4.2L420,100.8z M429.3,94.9l-2.7-4.2l4.2-2.7l2.7,4.2L429.3,94.9z
      M438.6,89.1l-2.7-4.2l4.2-2.7l2.7,4.2L438.6,89.1z M447.9,83.2l-2.7-4.2l4.2-2.7l2.7,4.2L447.9,83.2z M457.3,77.4l-2.7-4.2l4.2-2.7
      l2.7,4.2L457.3,77.4z M466.6,71.5l-2.7-4.2l4.2-2.7l2.7,4.2L466.6,71.5z M475.9,65.7l-2.7-4.2l4.2-2.7l2.7,4.2L475.9,65.7z
      M485.2,59.8l-2.7-4.2l4.2-2.7l2.7,4.2L485.2,59.8z M494.5,54l-2.7-4.2l4.2-2.7l2.7,4.2L494.5,54z M503.8,48.1l-2.7-4.2l4.2-2.7
      l2.7,4.2L503.8,48.1z M513.2,42.3l-2.7-4.2l4.2-2.7l2.7,4.2L513.2,42.3z M522.5,36.4l-2.7-4.2l4.2-2.7l2.7,4.2L522.5,36.4z
      M531.8,30.6l-2.7-4.2l4.2-2.7l2.7,4.2L531.8,30.6z M541.1,24.7l-2.7-4.2l4.2-2.7l2.7,4.2L541.1,24.7z M550.4,18.8l-2.7-4.2L552,12
      l2.7,4.2L550.4,18.8z M559.7,13L557,8.8l4.2-2.7l2.7,4.2L559.7,13z M569,7.1l-2.7-4.2l4.2-2.7l2.7,4.2L569,7.1z"
    />,
    <path
      d="M953,335.2l-4.2-2.7l2.7-4.2l4.2,2.7L953,335.2z M943.7,329.4l-4.2-2.7l2.7-4.2l4.2,2.7L943.7,329.4z
        M934.4,323.6l-4.2-2.7l2.7-4.2l4.2,2.7L934.4,323.6z M925,317.7l-4.2-2.7l2.7-4.2l4.2,2.7L925,317.7z M915.7,311.9l-4.2-2.7
        l2.7-4.2l4.2,2.7L915.7,311.9z M906.4,306l-4.2-2.7l2.7-4.2l4.2,2.7L906.4,306z M897.1,300.2l-4.2-2.7l2.7-4.2l4.2,2.7L897.1,300.2z
        "
    />,
    <path
      d="M815.5,424.4l-2.7-4.2l4.2-2.7l2.7,4.2L815.5,424.4z M824.8,418.6l-2.7-4.2l4.2-2.7l2.7,4.2L824.8,418.6z
        M834.1,412.7l-2.7-4.2l4.2-2.7l2.7,4.2L834.1,412.7z M843.4,406.9l-2.7-4.2l4.2-2.7l2.7,4.2L843.4,406.9z M852.8,401.1l-2.7-4.2
        l4.2-2.7l2.7,4.2L852.8,401.1z M862.1,395.2l-2.7-4.2l4.2-2.7l2.7,4.2L862.1,395.2z M871.4,389.4l-2.7-4.2l4.2-2.7l2.7,4.2
        L871.4,389.4z M880.7,383.5l-2.7-4.2l4.2-2.7l2.7,4.2L880.7,383.5z M890.1,377.7l-2.7-4.2l4.2-2.7l2.7,4.2L890.1,377.7z
        M899.4,371.9l-2.7-4.2l4.2-2.7l2.7,4.2L899.4,371.9z M908.7,366l-2.7-4.2l4.2-2.7l2.7,4.2L908.7,366z M918,360.2l-2.7-4.2l4.2-2.7
        l2.7,4.2L918,360.2z M927.3,354.3l-2.7-4.2l4.2-2.7l2.7,4.2L927.3,354.3z M936.7,348.5l-2.7-4.2l4.2-2.7l2.7,4.2L936.7,348.5z
        M946,342.7l-2.7-4.2l4.2-2.7l2.7,4.2L946,342.7z"
    />,
    <path
      d="M937,507.2l-4.2-2.7l2.7-4.2l4.2,2.7L937,507.2z M927.7,501.4l-4.2-2.7l2.7-4.2l4.2,2.7L927.7,501.4z
        M918.4,495.5l-4.2-2.7l2.7-4.2l4.2,2.7L918.4,495.5z M909.1,489.7l-4.2-2.7l2.7-4.2l4.2,2.7L909.1,489.7z M899.8,483.8l-4.2-2.7
        l2.7-4.2l4.2,2.7L899.8,483.8z M890.4,477.9l-4.2-2.7l2.7-4.2l4.2,2.7L890.4,477.9z M881.1,472.1l-4.2-2.7l2.7-4.2l4.2,2.7
        L881.1,472.1z M871.8,466.2l-4.2-2.7l2.7-4.2l4.2,2.7L871.8,466.2z M862.5,460.4l-4.2-2.7l2.7-4.2l4.2,2.7L862.5,460.4z
        M853.2,454.5l-4.2-2.7l2.7-4.2l4.2,2.7L853.2,454.5z M843.9,448.7l-4.2-2.7l2.7-4.2l4.2,2.7L843.9,448.7z M834.6,442.8l-4.2-2.7
        l2.7-4.2l4.2,2.7L834.6,442.8z M825.3,437l-4.2-2.7l2.7-4.2l4.2,2.7L825.3,437z M816,431.1l-4.2-2.7l2.7-4.2l4.2,2.7L816,431.1z"
    />,
  ],
  6: [
    <path
      d="M332.2,266.4l-4.2-2.7l2.7-4.2l4.2,2.7L332.2,266.4z M322.9,260.5l-4.2-2.7l2.7-4.2l4.2,2.7L322.9,260.5z
      M313.6,254.7l-4.2-2.7l2.7-4.2l4.2,2.7L313.6,254.7z M304.3,248.8l-4.2-2.7l2.7-4.2l4.2,2.7L304.3,248.8z M295.1,242.9l-4.2-2.7
      l2.7-4.2l4.2,2.7L295.1,242.9z M285.8,237l-4.2-2.7l2.7-4.2l4.2,2.7L285.8,237z M276.5,231.1l-4.2-2.7l2.7-4.2l4.2,2.7L276.5,231.1z
      M267.2,225.2l-3.2-2l2.7-4.2l3.2,2L267.2,225.2z"
    />,
    <path
      d="M388,301.7l-4.2-2.7l2.7-4.2l4.2,2.7L388,301.7z M378.7,295.8l-4.2-2.7l2.7-4.2l4.2,2.7L378.7,295.8z
      M369.4,290l-4.2-2.7l2.7-4.2l4.2,2.7L369.4,290z M360.1,284.1l-4.2-2.7l2.7-4.2l4.2,2.7L360.1,284.1z M350.8,278.2l-4.2-2.7
      l2.7-4.2l4.2,2.7L350.8,278.2z M341.5,272.3l-4.2-2.7l2.7-4.2l4.2,2.7L341.5,272.3z"
    />,
    <path
      id="Line_49"
      d="M344.7,331.2L342,327l4.2-2.7l2.7,4.2L344.7,331.2z M354,325.3l-2.7-4.2l4.2-2.7l2.7,4.2
      L354,325.3z M363.2,319.4l-2.7-4.2l4.2-2.7l2.7,4.2L363.2,319.4z M372.5,313.5l-2.7-4.2l4.2-2.7l2.7,4.2L372.5,313.5z M381.8,307.6
      l-2.7-4.2l4.2-2.7l2.7,4.2L381.8,307.6z"
    />,
    <path
      id="Line_45"
      d="M495,426.7l-4.2-2.7l2.7-4.2l4.2,2.7L495,426.7z M485.7,420.9l-4.2-2.7l2.7-4.2l4.2,2.7
      L485.7,420.9z M476.4,415l-4.2-2.7l2.7-4.2l4.2,2.7L476.4,415z M467.1,409.1l-4.2-2.7l2.7-4.2l4.2,2.7L467.1,409.1z M457.8,403.3
      l-4.2-2.7l2.7-4.2l4.2,2.7L457.8,403.3z M448.5,397.4l-4.2-2.7l2.7-4.2l4.2,2.7L448.5,397.4z M439.2,391.5l-4.2-2.7l2.7-4.2l4.2,2.7
      L439.2,391.5z M429.9,385.7l-4.3-2.7l2.7-4.2l4.2,2.7L429.9,385.7z M420.6,379.8l-4.3-2.7l2.7-4.2l4.2,2.7L420.6,379.8z
      M411.3,373.9l-4.2-2.7l2.7-4.2l4.2,2.7L411.3,373.9z M402,368l-4.2-2.7l2.7-4.2l4.2,2.7L402,368z M392.7,362.2l-4.2-2.7l2.7-4.2
      l4.2,2.7L392.7,362.2z M383.4,356.3l-4.2-2.7l2.7-4.2l4.2,2.7L383.4,356.3z M374.1,350.4l-4.2-2.7l2.7-4.2l4.2,2.7L374.1,350.4z
      M364.8,344.6l-4.2-2.7l2.7-4.2l4.2,2.7L364.8,344.6z M355.5,338.7l-4.3-2.7l2.7-4.2l4.2,2.7L355.5,338.7z M346.1,332.8l-4.1-2.6
      l2.7-4.2l4.1,2.6L346.1,332.8z"
    />,
    <path
      d="M505.9,424.4l-2.6-4.2l4.2-2.7l2.7,4.2L505.9,424.4z M515.2,418.5l-2.7-4.2l4.2-2.7l2.7,4.2L515.2,418.5z
      M524.5,412.7l-2.7-4.2l4.2-2.7l2.7,4.2L524.5,412.7z M533.9,406.9l-2.7-4.2l4.2-2.7l2.7,4.2L533.9,406.9z M543.2,401l-2.7-4.2
      l4.2-2.7l2.7,4.2L543.2,401z"
    />,
    <path
      id="Line_41"
      d="M647,456.2l-4.2-2.7l2.7-4.2l4.2,2.6L647,456.2z M637.7,450.4l-4.2-2.6l2.7-4.2l4.2,2.6
      L637.7,450.4z M628.3,444.6l-4.2-2.6l2.7-4.2l4.2,2.7L628.3,444.6z M619,438.7l-4.2-2.6l2.7-4.2l4.2,2.6L619,438.7z M609.7,432.9
      l-4.2-2.6l2.7-4.2l4.2,2.7L609.7,432.9z M600.4,427.1l-4.2-2.6l2.7-4.2l4.2,2.6L600.4,427.1z M591,421.3l-4.2-2.6l2.7-4.2l4.2,2.6
      L591,421.3z M581.7,415.4l-4.2-2.7l2.7-4.2l4.2,2.6L581.7,415.4z M572.4,409.6l-4.2-2.6l2.7-4.2l4.2,2.6L572.4,409.6z M563.1,403.8
      l-4-2.5l2.7-4.2l4,2.5L563.1,403.8z"
    />,
    <path
      d="M577.8,502.3l-2.7-4.2l4.2-2.7l2.7,4.2L577.8,502.3z M587.2,496.4l-2.7-4.2l4.2-2.7l2.7,4.2L587.2,496.4z
      M596.5,490.6l-2.7-4.2l4.2-2.7l2.7,4.2L596.5,490.6z M605.8,484.8l-2.7-4.2l4.2-2.7l2.7,4.2L605.8,484.8z M615.1,478.9l-2.7-4.2
      l4.2-2.7l2.7,4.2L615.1,478.9z M624.4,473.1l-2.7-4.2l4.2-2.7l2.7,4.2L624.4,473.1z M633.8,467.2l-2.7-4.2l4.2-2.7l2.7,4.2
      L633.8,467.2z M643.1,461.4l-2.7-4.2l4.2-2.7l2.7,4.2L643.1,461.4z"
    />,
    <path
      id="Line_42"
      d="M625,537.2l-4.2-2.7l2.7-4.2l4.2,2.7L625,537.2z M615.7,531.3l-4.2-2.7l2.7-4.2l4.2,2.7
      L615.7,531.3z M606.4,525.4l-4.2-2.7l2.7-4.2l4.2,2.7L606.4,525.4z M597.1,519.5l-4.2-2.7l2.7-4.2l4.2,2.7L597.1,519.5z
      M587.8,513.7l-4.2-2.7l2.7-4.2l4.2,2.7L587.8,513.7z M578.6,507.8l-4.2-2.7l2.7-4.2l4.2,2.7L578.6,507.8z"
    />,
    <path
      d="M647.7,529.5l-2.7-4.2l4.2-2.7l2.7,4.2L647.7,529.5z M657,523.6l-2.7-4.2l4.2-2.7l2.7,4.2L657,523.6z
      M666.3,517.8l-2.7-4.2l4.2-2.7l2.7,4.2L666.3,517.8z M675.6,512l-2.7-4.2l4.2-2.7l2.7,4.2L675.6,512z M684.9,506.1l-2.7-4.2
      l4.2-2.7l2.7,4.2L684.9,506.1z M694.3,500.3l-2.7-4.2l4.2-2.7l2.7,4.2L694.3,500.3z M703.6,494.5l-2.7-4.2l4.2-2.7l2.7,4.2
      L703.6,494.5z M712.9,488.6l-2.7-4.2l4.2-2.7l2.7,4.2L712.9,488.6z M722.2,482.8l-2.7-4.2l4.2-2.7l2.7,4.2L722.2,482.8z
      M731.6,476.9l-2.7-4.2l4.2-2.7l2.7,4.2L731.6,476.9z M740.9,471.1l-2.7-4.2l4.2-2.7l2.7,4.2L740.9,471.1z M750.2,465.3l-2.7-4.2
      l4.2-2.7l2.7,4.2L750.2,465.3z M759.5,459.4l-2.7-4.2l4.2-2.7l2.7,4.2L759.5,459.4z M768.9,453.6l-2.7-4.2l4.2-2.7l2.7,4.2
      L768.9,453.6z M778.2,447.8l-2.7-4.2l4.2-2.7l2.7,4.2L778.2,447.8z M787.5,441.9l-2.7-4.2l4.2-2.7l2.7,4.2L787.5,441.9z
      M796.8,436.1l-2.7-4.2l4.2-2.7l2.7,4.2L796.8,436.1z M806.1,430.2l-2.7-4.2l4.2-2.7l2.7,4.2L806.1,430.2z"
    />,
    <path
      d="M937,507.2l-4.2-2.7l2.7-4.2l4.2,2.7L937,507.2z M927.7,501.4l-4.2-2.7l2.7-4.2l4.2,2.7L927.7,501.4z
      M918.4,495.5l-4.2-2.7l2.7-4.2l4.2,2.7L918.4,495.5z M909.1,489.7l-4.2-2.7l2.7-4.2l4.2,2.7L909.1,489.7z M899.8,483.8l-4.2-2.7
      l2.7-4.2l4.2,2.7L899.8,483.8z M890.4,477.9l-4.2-2.7l2.7-4.2l4.2,2.7L890.4,477.9z M881.1,472.1l-4.2-2.7l2.7-4.2l4.2,2.7
      L881.1,472.1z M871.8,466.2l-4.2-2.7l2.7-4.2l4.2,2.7L871.8,466.2z M862.5,460.4l-4.2-2.7l2.7-4.2l4.2,2.7L862.5,460.4z
      M853.2,454.5l-4.2-2.7l2.7-4.2l4.2,2.7L853.2,454.5z M843.9,448.7l-4.2-2.7l2.7-4.2l4.2,2.7L843.9,448.7z M834.6,442.8l-4.2-2.7
      l2.7-4.2l4.2,2.7L834.6,442.8z M825.3,437l-4.2-2.7l2.7-4.2l4.2,2.7L825.3,437z M816,431.1l-4.2-2.7l2.7-4.2l4.2,2.7L816,431.1z"
    />,
    <path
      id="Line_43"
      d="M261.7,200.2L259,196l4.2-2.7l2.7,4.2L261.7,200.2z M271,194.4l-2.7-4.2l4.2-2.7l2.7,4.2
        L271,194.4z M280.3,188.5l-2.7-4.2l4.2-2.7l2.7,4.2L280.3,188.5z M289.6,182.7l-2.7-4.2l4.2-2.7l2.7,4.2L289.6,182.7z M298.9,176.8
        l-2.7-4.2l4.2-2.7l2.7,4.2L298.9,176.8z M308.2,171l-2.7-4.2l4.2-2.7l2.7,4.2L308.2,171z M317.5,165.1l-2.7-4.2l4.2-2.7l2.7,4.2
        L317.5,165.1z M326.9,159.3l-2.7-4.2l4.2-2.7l2.7,4.2L326.9,159.3z M336.2,153.4l-2.7-4.2l4.2-2.7l2.7,4.2L336.2,153.4z
        M345.5,147.6l-2.7-4.2l4.2-2.7l2.7,4.2L345.5,147.6z M354.8,141.7l-2.7-4.2l4.2-2.7l2.7,4.2L354.8,141.7z M364.1,135.9l-2.7-4.2
        l4.2-2.7l2.7,4.2L364.1,135.9z M373.4,130l-2.7-4.2l4.2-2.7l2.7,4.2L373.4,130z M382.7,124.2L380,120l4.2-2.7l2.7,4.2L382.7,124.2z
        M392.1,118.3l-2.7-4.2l4.2-2.7l2.7,4.2L392.1,118.3z M401.4,112.5l-2.7-4.2l4.2-2.7l2.7,4.2L401.4,112.5z M410.7,106.6l-2.7-4.2
        l4.2-2.7l2.7,4.2L410.7,106.6z M420,100.8l-2.7-4.2l4.2-2.7l2.7,4.2L420,100.8z M429.3,94.9l-2.7-4.2l4.2-2.7l2.7,4.2L429.3,94.9z
        M438.6,89.1l-2.7-4.2l4.2-2.7l2.7,4.2L438.6,89.1z M447.9,83.2l-2.7-4.2l4.2-2.7l2.7,4.2L447.9,83.2z M457.3,77.4l-2.7-4.2l4.2-2.7
        l2.7,4.2L457.3,77.4z M466.6,71.5l-2.7-4.2l4.2-2.7l2.7,4.2L466.6,71.5z M475.9,65.7l-2.7-4.2l4.2-2.7l2.7,4.2L475.9,65.7z
        M485.2,59.8l-2.7-4.2l4.2-2.7l2.7,4.2L485.2,59.8z M494.5,54l-2.7-4.2l4.2-2.7l2.7,4.2L494.5,54z M503.8,48.1l-2.7-4.2l4.2-2.7
        l2.7,4.2L503.8,48.1z M513.2,42.3l-2.7-4.2l4.2-2.7l2.7,4.2L513.2,42.3z M522.5,36.4l-2.7-4.2l4.2-2.7l2.7,4.2L522.5,36.4z
        M531.8,30.6l-2.7-4.2l4.2-2.7l2.7,4.2L531.8,30.6z M541.1,24.7l-2.7-4.2l4.2-2.7l2.7,4.2L541.1,24.7z M550.4,18.8l-2.7-4.2L552,12
        l2.7,4.2L550.4,18.8z M559.7,13L557,8.8l4.2-2.7l2.7,4.2L559.7,13z M569,7.1l-2.7-4.2l4.2-2.7l2.7,4.2L569,7.1z"
    />,
    <path
      id="Line_37"
      d="M811,157.2l-4.2-2.7l2.7-4.2l4.2,2.7L811,157.2z M801.7,151.4l-4.2-2.7l2.7-4.2l4.2,2.7
        L801.7,151.4z M792.4,145.5l-4.2-2.7l2.7-4.2l4.2,2.7L792.4,145.5z M783,139.7l-4.2-2.7l2.7-4.2l4.2,2.7L783,139.7z M773.7,133.9
        l-4.2-2.7l2.7-4.2l4.2,2.7L773.7,133.9z M764.4,128l-4.2-2.7l2.7-4.2l4.2,2.7L764.4,128z M755.1,122.2l-4.2-2.7l2.7-4.2l4.2,2.7
        L755.1,122.2z M745.8,116.3l-4.2-2.7l2.7-4.2l4.2,2.7L745.8,116.3z M736.4,110.5l-4.2-2.7l2.7-4.2l4.2,2.7L736.4,110.5z
        M727.1,104.6l-4.2-2.7l2.7-4.2l4.2,2.7L727.1,104.6z M717.8,98.8l-4.2-2.7l2.7-4.2l4.2,2.7L717.8,98.8z M708.5,93l-4.2-2.7l2.7-4.2
        l4.2,2.7L708.5,93z M699.2,87.1l-4.2-2.7l2.7-4.2l4.2,2.7L699.2,87.1z M689.8,81.3l-4.2-2.7l2.7-4.2l4.2,2.7L689.8,81.3z
        M680.5,75.4l-4.2-2.7l2.7-4.2l4.2,2.7L680.5,75.4z M671.2,69.6l-4.2-2.7l2.7-4.2l4.2,2.7L671.2,69.6z M661.9,63.7l-4.2-2.7l2.7-4.2
        l4.2,2.7L661.9,63.7z M652.6,57.9l-4.2-2.7L651,51l4.2,2.7L652.6,57.9z M643.3,52l-4.2-2.7l2.7-4.2l4.2,2.7L643.3,52z M633.9,46.2
        l-4.2-2.7l2.7-4.2l4.2,2.7L633.9,46.2z M624.6,40.4l-4.2-2.7l2.7-4.2l4.2,2.7L624.6,40.4z M615.3,34.5l-4.2-2.7l2.7-4.2l4.2,2.7
        L615.3,34.5z M606,28.7l-4.2-2.7l2.7-4.2l4.2,2.7L606,28.7z M596.7,22.8l-4.2-2.7l2.7-4.2l4.2,2.7L596.7,22.8z M587.3,17l-4.2-2.7
        l2.7-4.2l4.2,2.7L587.3,17z M578,11.1l-4.2-2.7l2.7-4.2l4.2,2.7L578,11.1z M568.7,5.3L567,4.2l2.7-4.2l1.7,1.1L568.7,5.3z"
    />,
    <path
      id="Line_40"
      d="M740.7,202.2L738,198l4.2-2.7l2.7,4.2L740.7,202.2z M749.9,196.3l-2.7-4.2l4.2-2.7l2.7,4.2
        L749.9,196.3z M759.2,190.3l-2.7-4.2l4.2-2.7l2.7,4.2L759.2,190.3z M768.4,184.4l-2.7-4.2l4.2-2.7l2.7,4.2L768.4,184.4z
        M777.7,178.4l-2.7-4.2l4.2-2.7l2.7,4.2L777.7,178.4z M786.9,172.4l-2.7-4.2l4.2-2.7l2.7,4.2L786.9,172.4z M796.2,166.5l-2.7-4.2
        l4.2-2.7l2.7,4.2L796.2,166.5z M805.4,160.5l-2.7-4.2l4.2-2.7l2.7,4.2L805.4,160.5z"
    />,
    <path
      d="M887.7,294.4l-4.2-2.7l2.7-4.2l4.2,2.7L887.7,294.4z M878.4,288.5l-4.2-2.7l2.7-4.2l4.2,2.7L878.4,288.5z
        M869.1,282.7l-4.2-2.7l2.7-4.2l4.2,2.7L869.1,282.7z M859.8,276.9l-4.2-2.7l2.7-4.2l4.2,2.7L859.8,276.9z M850.4,271l-4.2-2.7
        l2.7-4.2l4.2,2.7L850.4,271z M841.1,265.2l-4.2-2.7l2.7-4.2l4.2,2.7L841.1,265.2z M831.8,259.3l-4.2-2.7l2.7-4.2l4.2,2.7
        L831.8,259.3z M822.5,253.5l-4.2-2.7l2.7-4.2l4.2,2.7L822.5,253.5z M813.2,247.7L809,245l2.7-4.2l4.2,2.7L813.2,247.7z M803.8,241.8
        l-4.2-2.7l2.7-4.2l4.2,2.7L803.8,241.8z M794.5,236l-4.2-2.7l2.7-4.2l4.2,2.7L794.5,236z M785.2,230.2l-4.2-2.7l2.7-4.2l4.2,2.7
        L785.2,230.2z M775.9,224.3l-4.2-2.7l2.7-4.2l4.2,2.7L775.9,224.3z M766.5,218.5l-4.2-2.7l2.7-4.2l4.2,2.7L766.5,218.5z
        M757.2,212.6L753,210l2.6-4.2l4.2,2.7L757.2,212.6z M747.9,206.8l-4.2-2.7l2.7-4.2l4.2,2.7L747.9,206.8z"
    />,
  ],
  7: [
    <path
      id="Line_36"
      d="M966.7,495.2L964,491l4.2-2.7l2.7,4.2L966.7,495.2z M976,489.4l-2.7-4.2l4.2-2.7l2.7,4.2
      L976,489.4z M985.3,483.5l-2.7-4.2l4.2-2.7l2.7,4.2L985.3,483.5z M994.6,477.7l-2.7-4.2l4.2-2.7l2.7,4.2L994.6,477.7z M1003.9,471.8
      l-2.7-4.2l4.2-2.7l2.7,4.2L1003.9,471.8z M1013.2,466l-2.7-4.2l4.2-2.7l2.7,4.2L1013.2,466z M1022.5,460.1l-2.7-4.2l4.2-2.7l2.7,4.2
      L1022.5,460.1z M1031.8,454.2l-2.7-4.2l4.2-2.7l2.7,4.2L1031.8,454.2z M1041.2,448.4l-2.7-4.2l4.2-2.7l2.7,4.2L1041.2,448.4z
      M1050.5,442.5l-2.7-4.2l4.2-2.7l2.7,4.2L1050.5,442.5z M1059.8,436.7l-2.7-4.2l4.2-2.7l2.7,4.2L1059.8,436.7z M1069.1,430.8
      l-2.7-4.2l4.2-2.7l2.7,4.2L1069.1,430.8z M1078.4,425l-2.7-4.2l4.2-2.7l2.7,4.2L1078.4,425z M1087.7,419.1l-2.7-4.2l4.2-2.7l2.7,4.2
      L1087.7,419.1z M1097,413.2l-2.7-4.2l4.2-2.7l2.7,4.2L1097,413.2z M1106.3,407.4l-2.7-4.2l4.2-2.7l2.7,4.2L1106.3,407.4z
      M1115.7,401.5l-2.7-4.2l4.2-2.7l2.7,4.2L1115.7,401.5z M1125,395.7l-2.7-4.2l4.2-2.7l2.7,4.2L1125,395.7z M1134.3,389.8l-2.7-4.2
      l4.2-2.7l2.7,4.2L1134.3,389.8z M1143.6,384l-2.7-4.2l4.2-2.7l2.7,4.2L1143.6,384z M1152.9,378.1l-2.7-4.2l4.2-2.7l2.7,4.2
      L1152.9,378.1z M1162.2,372.3l-2.7-4.2l4.2-2.7l2.7,4.2L1162.2,372.3z M1171.5,366.4l-2.7-4.2l4.2-2.7l2.7,4.2L1171.5,366.4z
      M1180.8,360.5l-2.7-4.2l4.2-2.7l2.7,4.2L1180.8,360.5z M1190.1,354.7l-2.7-4.2l4.2-2.7l2.7,4.2L1190.1,354.7z"
    />,
    <path
      d="M937,507.2l-4.2-2.7l2.7-4.2l4.2,2.7L937,507.2z M927.7,501.4l-4.2-2.7l2.7-4.2l4.2,2.7L927.7,501.4z
      M918.4,495.5l-4.2-2.7l2.7-4.2l4.2,2.7L918.4,495.5z M909.1,489.7l-4.2-2.7l2.7-4.2l4.2,2.7L909.1,489.7z M899.8,483.8l-4.2-2.7
      l2.7-4.2l4.2,2.7L899.8,483.8z M890.4,477.9l-4.2-2.7l2.7-4.2l4.2,2.7L890.4,477.9z M881.1,472.1l-4.2-2.7l2.7-4.2l4.2,2.7
      L881.1,472.1z M871.8,466.2l-4.2-2.7l2.7-4.2l4.2,2.7L871.8,466.2z M862.5,460.4l-4.2-2.7l2.7-4.2l4.2,2.7L862.5,460.4z
      M853.2,454.5l-4.2-2.7l2.7-4.2l4.2,2.7L853.2,454.5z M843.9,448.7l-4.2-2.7l2.7-4.2l4.2,2.7L843.9,448.7z M834.6,442.8l-4.2-2.7
      l2.7-4.2l4.2,2.7L834.6,442.8z M825.3,437l-4.2-2.7l2.7-4.2l4.2,2.7L825.3,437z M816,431.1l-4.2-2.7l2.7-4.2l4.2,2.7L816,431.1z"
    />,
    <path
      d="M647.7,529.5l-2.7-4.2l4.2-2.7l2.7,4.2L647.7,529.5z M657,523.6l-2.7-4.2l4.2-2.7l2.7,4.2L657,523.6z
      M666.3,517.8l-2.7-4.2l4.2-2.7l2.7,4.2L666.3,517.8z M675.6,512l-2.7-4.2l4.2-2.7l2.7,4.2L675.6,512z M684.9,506.1l-2.7-4.2
      l4.2-2.7l2.7,4.2L684.9,506.1z M694.3,500.3l-2.7-4.2l4.2-2.7l2.7,4.2L694.3,500.3z M703.6,494.5l-2.7-4.2l4.2-2.7l2.7,4.2
      L703.6,494.5z M712.9,488.6l-2.7-4.2l4.2-2.7l2.7,4.2L712.9,488.6z M722.2,482.8l-2.7-4.2l4.2-2.7l2.7,4.2L722.2,482.8z
      M731.6,476.9l-2.7-4.2l4.2-2.7l2.7,4.2L731.6,476.9z M740.9,471.1l-2.7-4.2l4.2-2.7l2.7,4.2L740.9,471.1z M750.2,465.3l-2.7-4.2
      l4.2-2.7l2.7,4.2L750.2,465.3z M759.5,459.4l-2.7-4.2l4.2-2.7l2.7,4.2L759.5,459.4z M768.9,453.6l-2.7-4.2l4.2-2.7l2.7,4.2
      L768.9,453.6z M778.2,447.8l-2.7-4.2l4.2-2.7l2.7,4.2L778.2,447.8z M787.5,441.9l-2.7-4.2l4.2-2.7l2.7,4.2L787.5,441.9z
      M796.8,436.1l-2.7-4.2l4.2-2.7l2.7,4.2L796.8,436.1z M806.1,430.2l-2.7-4.2l4.2-2.7l2.7,4.2L806.1,430.2z"
    />,
    <path
      d="M815.5,424.4l-2.7-4.2l4.2-2.7l2.7,4.2L815.5,424.4z M824.8,418.6l-2.7-4.2l4.2-2.7l2.7,4.2L824.8,418.6z
      M834.1,412.7l-2.7-4.2l4.2-2.7l2.7,4.2L834.1,412.7z M843.4,406.9l-2.7-4.2l4.2-2.7l2.7,4.2L843.4,406.9z M852.8,401.1l-2.7-4.2
      l4.2-2.7l2.7,4.2L852.8,401.1z M862.1,395.2l-2.7-4.2l4.2-2.7l2.7,4.2L862.1,395.2z M871.4,389.4l-2.7-4.2l4.2-2.7l2.7,4.2
      L871.4,389.4z M880.7,383.5l-2.7-4.2l4.2-2.7l2.7,4.2L880.7,383.5z M890.1,377.7l-2.7-4.2l4.2-2.7l2.7,4.2L890.1,377.7z
      M899.4,371.9l-2.7-4.2l4.2-2.7l2.7,4.2L899.4,371.9z M908.7,366l-2.7-4.2l4.2-2.7l2.7,4.2L908.7,366z M918,360.2l-2.7-4.2l4.2-2.7
      l2.7,4.2L918,360.2z M927.3,354.3l-2.7-4.2l4.2-2.7l2.7,4.2L927.3,354.3z M936.7,348.5l-2.7-4.2l4.2-2.7l2.7,4.2L936.7,348.5z
      M946,342.7l-2.7-4.2l4.2-2.7l2.7,4.2L946,342.7z"
    />,
    <path
      d="M953,335.2l-4.2-2.7l2.7-4.2l4.2,2.7L953,335.2z M943.7,329.4l-4.2-2.7l2.7-4.2l4.2,2.7L943.7,329.4z
      M934.4,323.6l-4.2-2.7l2.7-4.2l4.2,2.7L934.4,323.6z M925,317.7l-4.2-2.7l2.7-4.2l4.2,2.7L925,317.7z M915.7,311.9l-4.2-2.7
      l2.7-4.2l4.2,2.7L915.7,311.9z M906.4,306l-4.2-2.7l2.7-4.2l4.2,2.7L906.4,306z M897.1,300.2l-4.2-2.7l2.7-4.2l4.2,2.7L897.1,300.2z
      "
    />,
  ],
  8: [
    <path
      id="Line_32"
      d="M1358,451.2l-4.2-2.7l2.7-4.2l4.2,2.7L1358,451.2z M1348.7,445.4l-4.2-2.7l2.7-4.2l4.2,2.7
      L1348.7,445.4z M1339.4,439.5l-4.2-2.7l2.7-4.2l4.2,2.7L1339.4,439.5z M1330.1,433.7l-4.2-2.7l2.7-4.2l4.2,2.7L1330.1,433.7z
      M1320.7,427.8l-4.2-2.7l2.7-4.2l4.2,2.7L1320.7,427.8z M1311.4,422l-4.2-2.7l2.7-4.2l4.2,2.7L1311.4,422z M1302.1,416.1l-4.2-2.7
      l2.7-4.2l4.2,2.7L1302.1,416.1z M1292.8,410.3l-4.2-2.7l2.7-4.2l4.2,2.7L1292.8,410.3z M1283.5,404.4l-4.2-2.7l2.7-4.2l4.2,2.7
      L1283.5,404.4z M1274.2,398.6l-4.2-2.7l2.7-4.2l4.2,2.7L1274.2,398.6z M1264.8,392.7l-4.2-2.7l2.7-4.2l4.2,2.7L1264.8,392.7z
      M1255.5,386.9l-4.2-2.7l2.7-4.2l4.2,2.7L1255.5,386.9z M1246.2,381l-4.2-2.7l2.7-4.2l4.2,2.7L1246.2,381z M1236.9,375.2l-4.2-2.7
      l2.7-4.2l4.2,2.7L1236.9,375.2z M1227.6,369.3l-4.2-2.7l2.7-4.2l4.2,2.7L1227.6,369.3z M1218.3,363.5l-4.2-2.7l2.7-4.2l4.2,2.7
      L1218.3,363.5z M1209,357.6l-4.2-2.7l2.7-4.2l4.2,2.7L1209,357.6z M1199.6,351.8l-4.2-2.7l2.7-4.2l4.2,2.7L1199.6,351.8z"
    />,
  ],
  9: [
    <path
      id="Line_39"
      d="M847.2,778.7l-2.7-4.2l4.2-2.7l2.7,4.2L847.2,778.7z M856.5,772.9l-2.7-4.2l4.2-2.7l2.7,4.2
        L856.5,772.9z M865.8,767.1l-2.7-4.2l4.2-2.7l2.7,4.2L865.8,767.1z M875.1,761.2l-2.7-4.2l4.2-2.7l2.7,4.2L875.1,761.2z
        M884.4,755.4l-2.7-4.2l4.2-2.7l2.7,4.2L884.4,755.4z M893.8,749.5l-2.7-4.2l4.2-2.7l2.7,4.2L893.8,749.5z M903.1,743.7l-2.7-4.2
        l4.2-2.7l2.7,4.2L903.1,743.7z M912.4,737.8l-2.7-4.2l4.2-2.7l2.7,4.2L912.4,737.8z M921.7,732l-2.7-4.2l4.2-2.7l2.7,4.2L921.7,732z
        M931,726.1l-2.7-4.2l4.2-2.7l2.7,4.2L931,726.1z M940.4,720.3l-2.7-4.2l4.2-2.7l2.7,4.2L940.4,720.3z M949.7,714.5l-2.7-4.2
        l4.2-2.7l2.7,4.2L949.7,714.5z M959,708.6l-2.7-4.2l4.2-2.7l2.7,4.2L959,708.6z M968.3,702.8l-2.7-4.2l4.2-2.7l2.7,4.2L968.3,702.8z
        M977.6,696.9l-2.7-4.2l4.2-2.7l2.7,4.2L977.6,696.9z M987,691.1l-2.7-4.2l4.2-2.7l2.7,4.2L987,691.1z M996.3,685.2l-2.7-4.2
        l4.2-2.7l2.7,4.2L996.3,685.2z M1005.6,679.4l-2.7-4.2l4.2-2.7l2.7,4.2L1005.6,679.4z M1014.9,673.6l-2.7-4.2l4.2-2.7l2.7,4.2
        L1014.9,673.6z M1024.2,667.7l-2.7-4.2l4.2-2.7l2.7,4.2L1024.2,667.7z M1033.6,661.9l-2.7-4.2l4.2-2.7l2.7,4.2L1033.6,661.9z
        M1042.9,656l-2.7-4.2l4.2-2.7l2.7,4.2L1042.9,656z M1052.2,650.2l-2.7-4.2l4.2-2.7l2.7,4.2L1052.2,650.2z M1061.5,644.3l-2.7-4.2
        l4.2-2.7l2.7,4.2L1061.5,644.3z M1070.8,638.5l-2.7-4.2l4.2-2.7l2.7,4.2L1070.8,638.5z M1080.2,632.7l-2.7-4.2l4.2-2.7l2.7,4.2
        L1080.2,632.7z M1089.5,626.8l-2.7-4.2l4.2-2.7l2.7,4.2L1089.5,626.8z M1098.8,621l-2.7-4.2l4.2-2.7l2.7,4.2L1098.8,621z
        M1108.1,615.1l-2.7-4.2l4.2-2.7l2.7,4.2L1108.1,615.1z M1117.4,609.3l-2.7-4.2l4.2-2.7l2.7,4.2L1117.4,609.3z M1126.8,603.4
        l-2.7-4.2l4.2-2.7l2.7,4.2L1126.8,603.4z M1136.1,597.6l-2.7-4.2l4.2-2.7l2.7,4.2L1136.1,597.6z M1145.4,591.8l-2.7-4.2l4.2-2.7
        l2.7,4.2L1145.4,591.8z M1154.7,585.9l-2.7-4.2l4.2-2.7l2.7,4.2L1154.7,585.9z M1164,580.1l-2.7-4.2l4.2-2.7l2.7,4.2L1164,580.1z
        M1173.4,574.2l-2.7-4.2l4.2-2.7l2.7,4.2L1173.4,574.2z M1182.7,568.4l-2.7-4.2l4.2-2.7l2.7,4.2L1182.7,568.4z M1192,562.5l-2.7-4.2
        l4.2-2.7l2.7,4.2L1192,562.5z M1201.3,556.7l-2.7-4.2l4.2-2.7l2.7,4.2L1201.3,556.7z M1210.6,550.9l-2.7-4.2l4.2-2.7l2.7,4.2
        L1210.6,550.9z M1220,545l-2.7-4.2l4.2-2.7l2.7,4.2L1220,545z M1229.3,539.2l-2.7-4.2l4.2-2.7l2.7,4.2L1229.3,539.2z M1238.6,533.3
        l-2.7-4.2l4.2-2.7l2.7,4.2L1238.6,533.3z M1247.9,527.5l-2.7-4.2l4.2-2.7l2.6,4.2L1247.9,527.5z M1257.2,521.6l-2.7-4.2l4.2-2.7
        l2.7,4.2L1257.2,521.6z M1266.6,515.8l-2.7-4.2l4.2-2.7l2.7,4.2L1266.6,515.8z M1275.9,510l-2.7-4.2l4.2-2.7l2.7,4.2L1275.9,510z
        M1285.2,504.1l-2.7-4.2l4.2-2.7l2.7,4.2L1285.2,504.1z M1294.5,498.3l-2.7-4.3l4.2-2.7l2.7,4.2L1294.5,498.3z M1303.8,492.4
        l-2.7-4.2l4.2-2.7l2.7,4.2L1303.8,492.4z M1313.2,486.6l-2.7-4.2l4.2-2.7l2.7,4.2L1313.2,486.6z M1322.5,480.7l-2.7-4.2l4.2-2.7
        l2.7,4.2L1322.5,480.7z M1331.8,474.9l-2.7-4.2l4.2-2.7l2.7,4.2L1331.8,474.9z M1341.1,469.1l-2.7-4.2l4.2-2.7l2.7,4.2L1341.1,469.1
        z M1350.4,463.2l-2.7-4.2l0.7-0.5l2.7,4.2L1350.4,463.2z"
    />,
  ],
  10: [
    <path
      d="M824,784.2l-4.2-2.7l2.7-4.2l4.2,2.7L824,784.2z M814.7,778.4l-4.2-2.7l2.7-4.2l4.2,2.7L814.7,778.4z
      M805.4,772.5l-4.2-2.7l2.7-4.2l4.2,2.7L805.4,772.5z M796.1,766.6l-4.2-2.7l2.7-4.2l4.2,2.7L796.1,766.6z M786.8,760.8l-4.2-2.7
      l2.7-4.2l4.2,2.7L786.8,760.8z M777.5,754.9l-4.2-2.7l2.7-4.2l4.2,2.7L777.5,754.9z M768.2,749.1l-4.2-2.7l2.7-4.2l4.2,2.7
      L768.2,749.1z M758.8,743.2l-4.2-2.7l2.7-4.2l4.2,2.7L758.8,743.2z M749.5,737.3l-4.2-2.7l2.7-4.2l4.2,2.7L749.5,737.3z
      M740.2,731.5l-4.2-2.7l2.7-4.2l4.2,2.7L740.2,731.5z M730.9,725.6l-4.2-2.7l2.7-4.2l4.2,2.7L730.9,725.6z M721.6,719.8l-4.2-2.7
      l2.7-4.2l4.2,2.7L721.6,719.8z M712.3,713.9l-4.2-2.7l2.7-4.2l4.2,2.7L712.3,713.9z M703,708l-4.2-2.7l2.7-4.2l4.2,2.7L703,708z
      M693.7,702.2l-4.2-2.7l2.7-4.2l4.2,2.7L693.7,702.2z M684.4,696.3l-4.2-2.7l2.7-4.2l4.2,2.7L684.4,696.3z M675.1,690.4l-4.2-2.7
      l2.7-4.2l4.2,2.7L675.1,690.4z M665.8,684.6l-4.2-2.7l2.7-4.2l4.2,2.7L665.8,684.6z M656.4,678.7l-4.2-2.7l2.7-4.2l4.2,2.7
      L656.4,678.7z M647.1,672.9l-4.2-2.7l2.7-4.2l4.2,2.7L647.1,672.9z M637.8,667l-4.2-2.7l2.7-4.2l4.2,2.7L637.8,667z M628.5,661.1
      l-4.2-2.7l2.7-4.2l4.2,2.7L628.5,661.1z M619.2,655.3l-4.2-2.7l2.7-4.2l4.2,2.7L619.2,655.3z M609.9,649.4l-4.2-2.7l2.7-4.2l4.2,2.7
      L609.9,649.4z M600.6,643.5l-4.2-2.7l2.7-4.2l4.2,2.7L600.6,643.5z M591.3,637.7l-4.2-2.7l2.7-4.2l4.2,2.7L591.3,637.7z M582,631.8
      l-4.2-2.7l2.7-4.2l4.2,2.7L582,631.8z M572.7,626l-4.2-2.7l2.7-4.2l4.2,2.7L572.7,626z M563.4,620.1l-4.2-2.7l2.7-4.2l4.2,2.7
      L563.4,620.1z M554.1,614.2l-4.2-2.7l2.7-4.2l4.2,2.7L554.1,614.2z"
    />,
    <path
      d="M544.8,608.4l-4.2-2.7l2.7-4.2l4.2,2.7L544.8,608.4z M535.4,602.5l-4.2-2.7l2.7-4.2l4.2,2.7L535.4,602.5z
      M526.1,596.7l-4.2-2.7l2.7-4.2l4.2,2.7L526.1,596.7z M516.8,590.8l-4.2-2.7l2.7-4.2l4.2,2.7L516.8,590.8z M507.5,584.9l-4.2-2.7
      l2.7-4.2l4.2,2.7L507.5,584.9z M498.2,579.1l-4.2-2.7l2.7-4.2l4.2,2.7L498.2,579.1z M488.9,573.2l-4.2-2.7l2.7-4.2l4.2,2.7
      L488.9,573.2z M479.6,567.3l-4.2-2.7l2.7-4.2l4.2,2.7L479.6,567.3z"
    />,
    <path
      d="M470.3,561.5l-4.2-2.7l2.7-4.2l4.2,2.7L470.3,561.5z M461,555.6l-4.2-2.7l2.7-4.2l4.2,2.7L461,555.6z
      M451.7,549.8l-4.2-2.7l2.7-4.2l4.2,2.7L451.7,549.8z M442.4,543.9l-4.2-2.7l2.7-4.2l4.2,2.7L442.4,543.9z M433.1,538l-4.2-2.7
      l2.7-4.2l4.2,2.7L433.1,538z M423.7,532.2l-4.2-2.7l2.7-4.2l4.2,2.7L423.7,532.2z M414.4,526.3l-4.2-2.7l2.7-4.2l4.2,2.7
      L414.4,526.3z M405.1,520.4l-4.2-2.7l2.7-4.2l4.2,2.7L405.1,520.4z M395.8,514.6l-4.2-2.7l2.7-4.2l4.2,2.7L395.8,514.6z
      M386.5,508.7l-4.2-2.7l2.7-4.2l4.2,2.7L386.5,508.7z"
    />,
    <path
      d="M377.2,502.9l-4.2-2.7l2.7-4.2l4.2,2.7L377.2,502.9z M367.9,497l-4.2-2.7l2.7-4.2l4.2,2.7L367.9,497z
      M358.6,491.1l-4.2-2.7l2.7-4.2l4.2,2.7L358.6,491.1z M349.3,485.3l-4.2-2.7l2.7-4.2l4.2,2.7L349.3,485.3z M340,479.4l-4.2-2.7
      l2.7-4.2l4.2,2.7L340,479.4z M330.7,473.6l-4.2-2.7l2.7-4.2l4.2,2.7L330.7,473.6z M321.4,467.7l-4.2-2.7l2.7-4.2l4.2,2.7
      L321.4,467.7z M312.1,461.8l-4.2-2.7l2.7-4.2l4.2,2.7L312.1,461.8z M302.7,456l-4.2-2.7l2.7-4.2l4.2,2.7L302.7,456z M293.4,450.1
      l-4.2-2.7l2.7-4.2l4.2,2.7L293.4,450.1z"
    />,
    <path
      d="M188.1,511.2l-2.7-4.2l4.2-2.6l2.7,4.2L188.1,511.2z M197.4,505.4l-2.7-4.2l4.2-2.7l2.6,4.2L197.4,505.4z
      M206.7,499.5l-2.7-4.2l4.2-2.7l2.7,4.2L206.7,499.5z M216.1,493.7l-2.7-4.2l4.2-2.7l2.7,4.2L216.1,493.7z M225.4,487.9l-2.7-4.2
      l4.2-2.7l2.7,4.2L225.4,487.9z M234.7,482l-2.7-4.2l4.2-2.7l2.7,4.2L234.7,482z M244,476.2l-2.7-4.2l4.2-2.7l2.7,4.2L244,476.2z
      M253.4,470.3l-2.7-4.2l4.2-2.7l2.7,4.2L253.4,470.3z M262.7,464.5l-2.7-4.2l4.2-2.7l2.7,4.2L262.7,464.5z M272,458.7l-2.7-4.2
      l4.2-2.7l2.7,4.2L272,458.7z M281.3,452.8l-2.7-4.2l4.2-2.7l2.7,4.2L281.3,452.8z"
    />,
    <path
      d="M132.2,546.2l-2.7-4.2l4.2-2.7l2.7,4.2L132.2,546.2z M141.5,540.4l-2.6-4.2l4.2-2.7l2.7,4.2L141.5,540.4z
      M150.8,534.6l-2.7-4.2l4.2-2.7l2.7,4.2L150.8,534.6z M160.1,528.7l-2.7-4.2l4.2-2.7l2.7,4.2L160.1,528.7z M169.5,522.9l-2.7-4.2
      l4.2-2.6l2.7,4.2L169.5,522.9z M178.8,517l-2.7-4.2l4.2-2.7l2.7,4.2L178.8,517z"
    />,
    <path
      d="M384.7,500.2L382,496l4.2-2.7l2.7,4.2L384.7,500.2z M394,494.4l-2.7-4.2l4.2-2.7l2.7,4.2L394,494.4z
      M403.3,488.6l-2.7-4.2l4.2-2.7l2.7,4.2L403.3,488.6z M412.6,482.7l-2.7-4.2l4.2-2.7l2.7,4.2L412.6,482.7z M422,476.9l-2.7-4.2
      l4.2-2.7l2.7,4.2L422,476.9z M431.3,471.1l-2.7-4.2l4.2-2.7l2.7,4.2L431.3,471.1z M440.6,465.2L438,461l4.2-2.7l2.7,4.2L440.6,465.2
      z M449.9,459.4l-2.7-4.2l4.2-2.7l2.7,4.2L449.9,459.4z M459.3,453.6l-2.7-4.2l4.2-2.7l2.7,4.2L459.3,453.6z M468.6,447.7l-2.6-4.2
      l4.2-2.7l2.7,4.2L468.6,447.7z M477.9,441.9l-2.7-4.2l4.2-2.7l2.7,4.2L477.9,441.9z M487.2,436l-2.7-4.2l4.2-2.7l2.7,4.2L487.2,436z
      M496.6,430.2l-2.7-4.2l4.2-2.7l2.7,4.2L496.6,430.2z"
    />,
    <path
      d="M505.9,424.4l-2.6-4.2l4.2-2.7l2.7,4.2L505.9,424.4z M515.2,418.5l-2.7-4.2l4.2-2.7l2.7,4.2L515.2,418.5z
      M524.5,412.7l-2.7-4.2l4.2-2.7l2.7,4.2L524.5,412.7z M533.9,406.9l-2.7-4.2l4.2-2.7l2.7,4.2L533.9,406.9z M543.2,401l-2.7-4.2
      l4.2-2.7l2.7,4.2L543.2,401z"
    />,
  ],
};
