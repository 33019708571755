import React, { Fragment } from 'react';
import { lines } from 'js/components/Path/lines';

import 'js/components/Path/styles.scss';

const Path = ({ id }) => (
  <svg className='path' viewBox={`0 0 1920 1080`}>
    <g transform='translate(214 106)'>
      {lines[id].map((line, i) => (
        <Fragment key={i}>{line}</Fragment>
      ))}
    </g>
  </svg>
);
export default Path;
