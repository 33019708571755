import React, { useRef, useContext, useEffect } from 'react';
import { DataContext } from 'js/context';

const Video = ({ onComplete }) => {
  const { data } = useContext(DataContext);

  const ref = useRef(null);

  useEffect(() => {
    if (ref?.current && data.activeSection && !!data.started.length) {
      const video = ref.current;
      video.currentTime = 0;
      data.showVideo ? video.play() : video.pause();
    }
  }, [data.showVideo, data.activeSection, ref, data.started]);

  useEffect(() => {
    if (data.replay) {
      const video = ref.current;
      video.currentTime = 0;
      data.showVideo ? video.play() : video.pause();
    }
  }, [data.replay, data.showVideo]);

  return (
    <video
      ref={ref}
      src={data.activeSection.video}
      playsInline
      onEnded={onComplete}
    />
  );
};

export default Video;
