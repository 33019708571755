import React, { useContext, useMemo } from 'react';
import Icon from 'js/components/Icon';
import { DataContext } from 'js/context/index';

const exceptions = [3, 12, 13, 14, 15];

const MapBtn = ({ data, activeSection, mapWidth, mapHeight }) => {
  const { id, name, question, button } = data;

  const { data: contextData, setData } = useContext(DataContext);

  const isActive = data.id === activeSection.btnId;

  const exceptionsCompleted = useMemo(
    () => exceptions.every((element) => contextData.finished.includes(element)),
    [contextData.finished]
  );

  const isFinished = useMemo(
    () =>
      data.id === 3
        ? exceptionsCompleted
        : contextData.finished.includes(data.id),
    [exceptionsCompleted, contextData.finished, data.id]
  );

  const isStarted = contextData.started.includes(data.id);

  const showQuestion = !isActive && activeSection?.connections?.includes(id);

  const x = (mapWidth * button.x) / 1920;
  const y = (mapHeight * button.y) / 1080;

  const onSelect = () => {
    setData({ ...contextData, activeSection: data, showVideo: true });
  };

  return (
    <div
      onClick={onSelect}
      className={`button ${isStarted ? 'button--gray' : ''} ${
        isFinished ? 'button--active' : ''
      } `}
      style={{
        transform: `translate(${x}px, ${y}px)`,
        width: button.width,
        height: button.height,
      }}
      key={data.id}>
      <span className='name'>{name}</span>

      {!!question && !isActive && (
        <div className={`question ${showQuestion ? 'question--show' : ''}`}>
          {question}
        </div>
      )}
      {isFinished && <Icon name='checkmark' />}
    </div>
  );
};
export default MapBtn;
