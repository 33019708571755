import React, { useState, useContext, useEffect, useCallback } from "react";

import { Console } from "js/components/Console/index.js";
import Video from "js/components/Video";
import VRoom from "js/components/VRoom";
import MainLayout from "js/Layouts";

import { DataContext } from "js/context";

const audio = new Audio("audio/main.mp3");

const Presentation = () => {
  audio.loop = "infinite";
  audio.volume = 0.1;
  audio.play();

  const { data, setData } = useContext(DataContext);

  const [showNext, setShowNext] = useState(
    !!data?.activeSection?.nextSections ?? false
  );

  const onCompleteSection = useCallback(() => {
    setData({
      ...data,
      showVideo: !!data.activeSection.nextSections,
      finished: [
        ...data.finished.filter((e) => e !== data.activeSection.id),
        data.activeSection.id,
      ],
      showVr: data.activeSection.id === 3,
      replay: false,
    });
    setShowNext(!!data.activeSection.nextSections);
  }, [data, setData]);

  useEffect(() => {
    if (audio) {
      audio.volume = data.audioMuted ? 0 : 0.02;
    }
  }, [data.audioMuted]);

  useEffect(() => {
    if (
      data.started.length &&
      !data.started.includes(data.activeSection.id) &&
      !data.finished.includes(data.activeSection.id)
    ) {
      setData({
        ...data,
        started: [...data.started, data.activeSection.id],
      });
    }
  }, [data, setData]);

  useEffect(() => {
    setShowNext(false);
  }, [data.activeSection, data.replay]);

  if (data.showVr) {
    return (
      <MainLayout finished={showNext || data.showVr}>
        <VRoom />
      </MainLayout>
    );
  } else if (data.activeSection.id === 12) {
    return (
      <MainLayout finished={showNext || data.showVr}>
        <Console onComplete={() => onCompleteSection()} />
      </MainLayout>
    );
  }

  return (
    <MainLayout finished={showNext || data.showVr}>
      {data.activeSection.id !== 12 && !data.showVr && (
        <Video onComplete={() => onCompleteSection()} />
      )}
    </MainLayout>
  );
};

export default Presentation;
