import React, { useState, useContext } from 'react';
import { DataContext } from 'js/context';

import 'js/components/Console/BaseImages/styles.scss';

const Image = ({ src, alt, opacity = false, className = '' }) => {
  const { data } = useContext(DataContext);
  const { isMobile, multiplier, base } = data;

  const size = (multiplier * 1022) / base;

  return (
    <img
      style={{
        top: 0,
        left: 0,
        right: isMobile ? 0 : 'auto',
        margin: 'auto',
        width: size,
        height: size,
        opacity: opacity,
      }}
      className={className}
      src={src}
      alt={alt}
    />
  );
};

export const BaseImages = ({ active }) => {
  const [showImages, setShowImages] = useState(false);

  const { data } = useContext(DataContext);
  const { isMobile, multiplier, base } = data;

  const size = (multiplier * 1022) / base;

  return (
    <div className={`base-images ${showImages ? 'base-images--show' : ''}`}>
      <video
        src='videos/console.mp4'
        autoPlay
        playsInline
        onEnded={() => setShowImages(true)}
        style={{
          top: -size / 2,
          left: 0,
          right: isMobile ? 0 : 'auto',
          width: size,
          height: size * 2,
          margin: 'auto',
        }}
      />

      <Image
        src='images/console/base/base_0.png'
        alt='dark room'
        opacity={
          !active.includes('projectors') && !active.includes('smartWatches')
            ? 1
            : 0
        }
      />

      <Image
        src='images/console/base/base_1.png'
        alt='light room'
        opacity={
          active.includes('projectors') && !active.includes('smartWatches')
            ? 1
            : 0
        }
      />

      <Image
        src='images/console/base/watches_0.png'
        alt='smart watches'
        opacity={
          active.includes('smartWatches') && !active.includes('projectors')
            ? 1
            : 0
        }
      />

      <Image
        src='images/console/base/watches_1.png'
        alt='smart watches'
        opacity={
          active.includes('smartWatches') && active.includes('projectors')
            ? 1
            : 0
        }
      />

      <Image
        src='images/console/base/projectors.png'
        alt='projectors'
        opacity={active.includes('projectors') ? 1 : 0}
      />

      <Image
        src='images/console/base/laser_sensors.png'
        alt='laser sensors'
        opacity={active.includes('laserSensors') ? 1 : 0}
      />

      <Image
        src='images/console/base/laser_sensors_FX.png'
        alt='laser sensors 5'
        className={active.includes('laserSensors') ? 'animated' : ''}
      />

      <Image
        src='images/console/base/depth_cameras.png'
        alt='depth cameras'
        opacity={active.includes('depthCameras') ? 1 : 0}
      />

      {active.includes('smartWatches') ? (
        <Image
          src='images/console/base/depth_cameras_FX_watches.png'
          alt='depth cameras 2'
          className={active.includes('depthCameras') ? 'animated' : ''}
        />
      ) : (
        <Image
          src='images/console/base/depth_cameras_FX_nowatches.png'
          alt='depth cameras'
          className={active.includes('depthCameras') ? 'animated' : ''}
        />
      )}
    </div>
  );
};
