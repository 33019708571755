import React, { memo, useContext } from 'react';
import { useDrop } from 'react-dnd';
import { DataContext } from 'js/context';

import 'js/components/Console/Dustbin/styles.scss';

export const Dustbin = memo(function Dustbin({
  icon,
  accept,
  width,
  height,
  x,
  y,
  onDrop,
  dragged,
}) {
  const { data } = useContext(DataContext);
  const { isMobile, multiplier, base } = data;

  const [, drop] = useDrop({
    accept,
    drop: onDrop,
  });

  return (
    <img
      ref={drop}
      className='dustbin'
      style={{
        top: (multiplier * y) / base,
        left: isMobile ? 0 : (multiplier * x) / base,
        right: isMobile ? 0 : 'auto',
        margin: 'auto',
        width: (multiplier * width) / base,
        zIndex: dragged === accept ? 999 : 1,
        opacity: dragged === accept ? 1 : 0,
        height: (multiplier * height) / base,
      }}
      src={icon}
      alt={accept}
    />
  );
});
