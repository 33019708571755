const icons = {
  checkmark: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="49"
      height="49"
      viewBox="0 0 49 49"
    >
      <defs>
        <filter
          id="Ellipse_4"
          x="0"
          y="0"
          width="49"
          height="49"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dx="-3" dy="3" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="5" result="blur" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g
        id="Group_42"
        data-name="Group 42"
        transform="translate(-2311 237.218)"
      >
        <g
          transform="matrix(1, 0, 0, 1, 2311, -237.22)"
          filter="url(#Ellipse_4)"
        >
          <circle
            id="Ellipse_4-2"
            data-name="Ellipse 4"
            cx="9.5"
            cy="9.5"
            r="9.5"
            transform="translate(18 12)"
            fill="#45f2a7"
          />
        </g>
        <path
          id="Path_1"
          data-name="Path 1"
          d="M2368.393,2352.809l4.937,5.113,7.361-7.8"
          transform="translate(-36.15 -2569.63)"
          fill="none"
          stroke="#fff"
          strokeWidth="3"
        />
      </g>
    </svg>
  ),
  close: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="45.255"
      height="45.255"
      viewBox="0 0 45.255 45.255"
    >
      <g
        id="Group_17"
        data-name="Group 17"
        transform="translate(-1326.532 1283.399) rotate(-45)"
      >
        <line
          id="Line_6"
          data-name="Line 6"
          y2="54"
          transform="translate(1845.5 35.5)"
          fill="none"
          stroke="#fff"
          strokeWidth="10"
        />
        <line
          id="Line_7"
          data-name="Line 7"
          y2="54"
          transform="translate(1872.5 62.5) rotate(90)"
          fill="none"
          stroke="#fff"
          strokeWidth="10"
        />
      </g>
    </svg>
  ),
};

const Icon = ({ name, className = "" }) => (
  <span className={`icon ${className}`}>{icons[name]}</span>
);

export default Icon;
