import React, { useContext, useMemo } from "react";

import { sections } from "data/index";
import { DataContext } from "js/context";

import "./styles.scss";

const exceptions = [12, 13, 14, 15];
const productSection = sections.find((section) => section.id === 3);

const MainLayout = ({ children, finished }) => {
  const { data, setData } = useContext(DataContext);

  const nextSections = data?.activeSection?.nextSections;

  const showButtons = finished && nextSections;

  const backToProduct = useMemo(
    () => !!exceptions.includes(data.activeSection.id),
    [data]
  );

  return (
    <div
      className={`presentation ${data.showVideo ? "presentation--show" : ""}`}
      style={{
        transform: `scale(${data.showVideo ? 1 : 0})`,
      }}>
      {!!data.started.length && (
        <button
          className='close'
          onClick={() =>
            setData(
              backToProduct
                ? {
                    ...data,
                    activeSection: productSection,
                    showVr: true,
                    replay: false,
                  }
                : { ...data, showVideo: false, showVr: false, replay: false }
            )
          }>
          <img src='images/arrow.png' alt='arrow right' />
          {backToProduct ? (
            <span>BACK TO PRODUCT</span>
          ) : (
            <span>{data.started.length > 1 ? "BACK" : "GO"} TO MAP</span>
          )}
        </button>
      )}

      {showButtons && (
        <div className='presentation__buttons'>
          {nextSections.map((nextId) => {
            const nextData = sections.find((section) => section.id === nextId);
            const clicked = data.finished.includes(nextId);

            return (
              <button
                key={nextId}
                className={clicked ? "button button--clicked" : "button"}
                onClick={() =>
                  setData({
                    ...data,
                    showVideo: true,
                    activeSection: nextData,
                    replay: nextData.id === data.activeSection.id,
                    showVr: false,
                  })
                }>
                <span className='name'>{nextData.question}</span>
              </button>
            );
          })}
        </div>
      )}
      {children}
    </div>
  );
};

export default MainLayout;
